import React, { useState } from "react";
import { useCalcContext } from "../Contexts/CalcContext";
import { useAuthContext } from "../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { MdAddCircle } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { withAlert } from "react-alert";
import CustomTooltip from "./CustomToolTip";

const Calculator = ({ advanced, calculate, home, btn, small, alert }) => {
  const { userdata, user } = useAuthContext();
  const navigate = useNavigate();
  const [terms, setTerms] = useState(false);
  const [insurance, setInsurance] = useState(true);
  const [first, setFirst] = useState(true);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const { calc, mode, setMode, packages, setPackages, setInputValues } =
    useCalcContext();
  const [homeObj, setHome] = useState({ residence: "ESPAÑA" });
  const [loading, setLoading] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [receiverP, setReceiverP] = useState("");
  const [receiverP2, setReceiverP2] = useState("");
  const [receiverID, setReceiverID] = useState("");
  const [valor, setValor] = useState("");



  function Calculate(packages) {
    if (mode === "Aéreo") {
      for (let i = 0; i < packages.length; i++) {
        // let volume =
        //   (calc.current[`dimension${i + 1}`].largo *
        //     calc.current[`dimension${i + 1}`].ancho *
        //     calc.current[`dimension${i + 1}`].alto) /
        //   28326.84;

        // let pesovolume =
        //   (calc.current[`dimension${i + 1}`].largo *
        //     calc.current[`dimension${i + 1}`].ancho *
        //     calc.current[`dimension${i + 1}`].alto) /
        //   6000;
        // let totalCalc;
        // if (calc.current[`dimension${i + 1}`].peso > 1) {
        //   totalCalc = pesovolume > calc.current[`dimension${i + 1}`].peso
        //     ? pesovolume * 14.99 + (calculate ? 3 : 0)
        //     : calc.current[`dimension${i + 1}`].peso * 14.99 + (calculate ? 3 : 0);
        // } else {
        //   totalCalc = 24.99;
        // }

        // calc.current[`total${i + 1}`] = Number(totalCalc).toFixed(2);
        // calc.current[`volume${i + 1}`] = pesovolume;

        let result;
        let dimension = calc.current[`dimension${i + 1}`];
        let volumeWeight = (dimension.largo * dimension.ancho * dimension.alto) / 6000;

        if (dimension.peso <= 1.6 && volumeWeight > dimension.peso) {
          result = volumeWeight * 14.99;
        } else if (dimension.peso > 1.6 && volumeWeight < dimension.peso) {
          result = dimension.peso * 14.99;
        } else if (dimension.peso > 1.6 && volumeWeight > dimension.peso) {
          result = volumeWeight * 14.99;
        } else if (dimension.peso <= 1.6 && volumeWeight < dimension.peso) {
          result = 24.99;
        } else {
          result = dimension.peso * 14.99;
        }
        calc.current[`total${i + 1}`] = Number(result).toFixed(2);
        let pesovolume =
          (calc.current[`dimension${i + 1}`].largo *
            calc.current[`dimension${i + 1}`].ancho *
            calc.current[`dimension${i + 1}`].alto) /
          6000;
          
        calc.current[`volume${i + 1}`] = pesovolume;
      }

      let totals = packages.map((elem, i) => {
        return calc.current[`total${i + 1}`];
      });

      let volumes = packages.map((elem, i) => {
        return calc.current[`volume${i + 1}`];
      });

      let pesos = packages.map((elem, i) => {
        return calc.current[`dimension${i + 1}`].peso;
      });

      let totalCalc = totals.reduce(
        (acc, amount) => Number(acc ? acc : 0) + Number(amount ? amount : 0)
      );

      let volumeCalc = volumes.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let pesosCalc = pesos.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );

      calc.current.total = Number(Number(totalCalc).toFixed(2))
        .toLocaleString("en-EN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replaceAll(".", "dd")
        .replaceAll(",", "cc")
        .replaceAll("cc", ".")
        .replaceAll("dd", ",") + "€";
      calc.current.volume = volumeCalc.toFixed(2);
      calc.current.peso = pesosCalc;

      const formatedtotal = Number(totalCalc).toLocaleString("en-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",");

      if (document.getElementById("total")) {
        document.getElementById("total").value = totalCalc
          ? formatedtotal
          : 0.0;
      }
    } else {
      for (let i = 0; i < packages.length; i++) {
        let totalcalc =
          (((calc.current[`dimension${i + 1}`].largo *
            calc.current[`dimension${i + 1}`].ancho *
            calc.current[`dimension${i + 1}`].alto) /
            28326.84) *
            34.5) + (calculate ? 38 : 0);
        let volume =
          (calc.current[`dimension${i + 1}`].largo *
            calc.current[`dimension${i + 1}`].ancho *
            calc.current[`dimension${i + 1}`].alto) /
          28326.84;
        let pesoval =
          (calc.current[`dimension${i + 1}`].largo *
            calc.current[`dimension${i + 1}`].ancho *
            calc.current[`dimension${i + 1}`].alto) /
          6000;
        calc.current[`total${i + 1}`] = totalcalc.toFixed(2);
        calc.current[`volume${i + 1}`] = pesoval;
      }

      let totals = packages.map((elem, i) => {
        return calc.current[`total${i + 1}`];
      });

      let volumes = packages.map((elem, i) => {
        return calc.current[`volume${i + 1}`];
      });

      let pesos = packages.map((elem, i) => {
        return calc.current[`dimension${i + 1}`].peso;
      });

      let totalCalc = totals.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let volumeCalc = volumes.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let pesosCalc = pesos.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );

      calc.current.total = Number(Number(totalCalc).toFixed(2))
        .toLocaleString("en-EN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replaceAll(".", "dd")
        .replaceAll(",", "cc")
        .replaceAll("cc", ".")
        .replaceAll("dd", ",") + "€";

      calc.current.volume = volumeCalc.toFixed(2);
      calc.current.peso = pesosCalc;

      document.getElementById("total").value = totalCalc
        ? Number(Number(totalCalc).toFixed(2)).toLocaleString("en-EN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",")
        : 0.0;
    }
  }


  //   function Calculate(packages) {
  //     if (mode === "Aéreo") {
  //         for (let i = 0; i < packages.length; i++) {
  //             let volume =
  //                 (calc.current[`dimension${i + 1 + ""}`].largo *
  //                     calc.current[`dimension${i + 1 + ""}`].ancho *
  //                     calc.current[`dimension${i + 1 + ""}`].alto) /
  //                 28326.84;

  //             let pesovolume =
  //                 (calc.current[`dimension${i + 1 + ""}`].largo *
  //                     calc.current[`dimension${i + 1 + ""}`].ancho *
  //                     calc.current[`dimension${i + 1 + ""}`].alto) /
  //                 6000;

  //             // Check if weight is less than or equal to 1 kg
  //             let weight = calc.current[`dimension${i + 1 + ""}`].peso;
  //             let totalCalc;
  //             if (weight <= 1) {
  //                 totalCalc = 24.99 + (calculate ? 3 : 0);
  //             } else {
  //                 totalCalc =
  //                     pesovolume > weight
  //                         ? pesovolume * 14.99 + (calculate ? 3 : 0)
  //                         : weight * 14.99 + (calculate ? 3 : 0);
  //             }

  //             calc.current[`total${i + 1 + ""}`] = Number(totalCalc).toFixed(2);
  //             calc.current[`volume${i + 1 + ""}`] = pesovolume;
  //         }

  //         let totals = packages.map((elem, i) => {
  //             return calc.current[`total${i + 1}`];
  //         });

  //         let volumes = packages.map((elem, i) => {
  //             return calc.current[`volume${i + 1}`];
  //         });

  //         let pesos = packages.map((elem, i) => {
  //             return calc.current[`dimension${i + 1 + ""}`].peso;
  //         });

  //         let totalCalc = totals.reduce(
  //             (acc, amount) => Number(acc ? acc : 0) + Number(amount ? amount : 0)
  //         );

  //         let volumeCalc = volumes.reduce(
  //             (acc, amount) => Number(acc) + Number(amount)
  //         );
  //         let pesosCalc = pesos.reduce(
  //             (acc, amount) => Number(acc) + Number(amount)
  //         );

  //         calc.current.total = Number(Number(totalCalc).toFixed(2)).
  //             toLocaleString("en-EN", {
  //                 minimumFractionDigits: 2,
  //                 maximumFractionDigits: 2,
  //             }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",") + "€";
  //         calc.current.volume = volumeCalc.toFixed(2);
  //         calc.current.peso = pesosCalc;

  //         const formatedtotal = Number(totalCalc).toLocaleString("en-EN", {
  //             minimumFractionDigits: 2,
  //             maximumFractionDigits: 2,
  //         }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",");

  //         if (document.getElementById("total")) {
  //             document.getElementById("total").value = totalCalc
  //                 ? formatedtotal
  //                 : 0.0;
  //         }
  //     } else {
  //         for (let i = 0; i < packages.length; i++) {
  //             let totalcalc =
  //                 (((calc.current[`dimension${i + 1 + ""}`].largo *
  //                     calc.current[`dimension${i + 1 + ""}`].ancho *
  //                     calc.current[`dimension${i + 1 + ""}`].alto) /
  //                     28326.84) *
  //                     34.5) + (calculate ? 38 : 0);
  //             let volume =
  //                 (calc.current[`dimension${i + 1 + ""}`].largo *
  //                     calc.current[`dimension${i + 1 + ""}`].ancho *
  //                     calc.current[`dimension${i + 1 + ""}`].alto) /
  //                 28326.84;
  //             let pesoval =
  //                 (calc.current[`dimension${i + 1 + ""}`].largo *
  //                     calc.current[`dimension${i + 1 + ""}`].ancho *
  //                     calc.current[`dimension${i + 1 + ""}`].alto) /
  //                 6000;
  //             calc.current[`total${i + 1 + ""}`] = totalcalc.toFixed(2);
  //             calc.current[`volume${i + 1 + ""}`] = pesoval;

  //             // console.log(calc);
  //         }

  //         let totals = packages.map((elem, i) => {
  //             return calc.current[`total${i + 1}`];
  //         });

  //         let volumes = packages.map((elem, i) => {
  //             return calc.current[`volume${i + 1}`];
  //         });

  //         let pesos = packages.map((elem, i) => {
  //             return calc.current[`dimension${i + 1 + ""}`].peso;
  //         });

  //         let totalCalc = totals.reduce(
  //             (acc, amount) => Number(acc) + Number(amount)
  //         );
  //         let volumeCalc = volumes.reduce(
  //             (acc, amount) => Number(acc) + Number(amount)
  //         );
  //         let pesosCalc = pesos.reduce(
  //             (acc, amount) => Number(acc) + Number(amount)
  //         );

  //         calc.current.total = Number(Number(totalCalc).toFixed(2)).
  //             toLocaleString("en-EN", {
  //                 minimumFractionDigits: 2,
  //                 maximumFractionDigits: 2,
  //             }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",") + "€";

  //         calc.current.volume = volumeCalc.toFixed(2);
  //         calc.current.peso = pesosCalc;

  //         document.getElementById("total").value = totalCalc
  //             ? Number(Number(totalCalc).toFixed(2)).toLocaleString("en-EN", {
  //                 minimumFractionDigits: 2,
  //                 maximumFractionDigits: 2,
  //             }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",")
  //             : 0.0;
  //     }
  // }

  const HandleChange = (e) => {
    setHome((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const countries = [
    "Alemania",
    "Bélgica",
    "Croacia",
    "Dinamarca",
    "España",
    "Francia",
    "Irlanda",
    "Letonia",
    "Luxemburgo",
    "Países Bajos",
    "Suecia",
    "Bulgaria",
    "Eslovaquia",
    "Estonia",
    "Grecia",
    "Malta",
    "Polonia",
    "República Checa",
    "Austria",
    "Chipre",
    "Eslovenia",
    "Finlandia",
    "Hungría",
    "Italia",
    "Lituania",
    "Portugal",
    "Rumanía",
  ];

  const CheckStatus = async () => {
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_BASE_URL + "/api/orders/checkstatus",
        {
          orderId: id.replace("PQ", "").replace("pq", ""),
        }
      );
      setStatus(data?.status);
    } catch (err) {
      alert.show(<span className="small">Número de Orden no existe</span>, {
        type: "error",
      });
      console.log(err);
    }
  };

  const CheckColor = (elem) => {
    let color = "";
    if (
      elem.toUpperCase() === "PENDIENTE" ||
      elem.toUpperCase() === "RECOGIENDO"
    ) {
      color = "red";
    } else if (elem.toUpperCase() === "ADUANAS") {
      color = "yellow";
    } else if (
      elem.toUpperCase() === "ENTREGADO" ||
      elem.toUpperCase() === "DISPONIBLE"
    ) {
      color = "green";
    } else {
      color = "blue";
    }

    return color;
  };

  const Continue = async () => {
    if (user) {
      if (Object.values(userdata).length > 12) {
        if (calculate) {
          if (terms) {
            if (Object.values(calc.current).length >= 5) {
              setLoading(true);
              try {
                const { data } = await axios.post(
                  process.env.REACT_APP_BASE_URL + "/api/orders/new",
                  {
                    userId: userdata._id,
                    name: userdata?.name + " " + userdata?.surname,
                    phone: userdata.phone,
                    dimensions: packages.map((elem, i) => {
                      return [calc.current[`dimension${i + 1 + ""}`]];
                    }),
                    peso: calc.current.peso,
                    receiver: calc.current.receiver,
                    total: calc.current.total,
                    modality: mode,
                    remarks: remarks,
                    volume: calc.current.volume,
                    Bultos: packages.length,
                    status: home ? "Recogiendo" : "Pendiente",
                    type:
                      Object.keys(homeObj).length > 1
                        ? "Solicitar Recogida"
                        : "Envío de Paquete",
                    agency: userdata.agency,
                    valor: valor,
                    home: Object.keys(homeObj).length > 1 ? homeObj : {},
                    email: userdata.email,
                    content: calc.current.content,
                    insurance: insurance,
                    receiver,
                    receiverID,
                    receiverP,
                    receiverP2
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      token: Cookies.get("token"),
                    },
                  }
                );
                calc.current = { total: "" };
                setHome({ residence: "ESPAÑA" });
                setReceiver("")
                setReceiverID("")
                setReceiverP("")
                setReceiverP2("")
                setValor("");
                document.getElementById("largo").value = "";
                document.getElementById("ancho").value = "";
                document.getElementById("alto").value = "";
                document.getElementById("peso").value = "";
                if (document.getElementById("total")) {
                  document.getElementById("total").value = "";
                }
                document.querySelectorAll(".form-control").forEach((elem) => {
                  elem.value = "";
                });
                setPackages([0]);

                alert.show(
                  <span className="small">Tu solicitud ha sido enviada.</span>,
                  {
                    type: "success",
                  }
                );
                setLoading(false);
              } catch (err) {
                console.log(err);
                setLoading(false);
              }
            } else {
              alert.show(
                <span className="small">
                  Error. Faltan datos por completar en el formulario
                </span>,
                {
                  type: "error",
                }
              );
              setLoading(false);
            }
          } else {
            alert.show(
              <span className="small">
                Acepta nuestros Términos y Condiciones
              </span>,
              {
                type: "error",
              }
            );
          }
        } else {
          navigate("/hacerenvio?dropoff=true");
        }
      } else {
        alert.show(
          <span className="small">
            Necesitas completar tus datos personales en tu sección Mis Datos.
          </span>,
          {
            type: "error",
          }
        );
      }
    } else {
      navigate("/login");
    }
  };

  const DeletePackage = (i) => {
    const pckgs = packages.filter((elem) => {
      return elem != i;
    });

    setPackages(pckgs);
    Calculate(pckgs);

    delete calc.current[`dimension${i + 1}`];
    delete calc.current[`total${i + 1}`];
    delete calc.current[`volume${i + 1}`];
  };

  return (
    <div className="rounded calculator">
      {!advanced && (
        <div
          className="curr w-100 fs-5 bg-blue rounded mb-3"
          style={{ border: 0 }}
        >
          <p
            className="w-50 py-2 text-center bg-blue rounded"
            type="button"
            onClick={() => setFirst(true)}
            style={{
              opacity: first ? 0.7 : 1,
            }}
          >
            Calcular
          </p>
          <p
            className="w-50 py-2 text-center bg-blue rounded local"
            style={{ borderLeft: "2px solid white", opacity: !first ? 0.7 : 1 }}
            type="button"
            onClick={() => setFirst(false)}
          >
            Localizador
          </p>
        </div>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (first) {
            Continue();
          } else {
            CheckStatus();
          }
        }}
      >
        {home && (
          <>
            <div className="register-fields mt-3">
              <label>Domicilio para Recogidas:</label>
              <div className="d-flex justify-content-between flex-wrap align-items-center mt-1">
                <input
                  type={"text"}
                  placeholder="Vía"
                  id="homevia"
                  onChange={(e) => HandleChange(e)}
                  className="form-control border rounded-0 mt-2 field mt-2"
                  required
                />
                <input
                  type={"text"}
                  placeholder="Número"
                  id="homenumber"
                  onChange={(e) => HandleChange(e)}
                  className="form-control border rounded-0 mt-2 field mt-2"
                  required
                />
              </div>
            </div>

            <div className="d-flex justify-content-between flex-wrap register-fields mt-1">
              <input
                type={"text"}
                placeholder="Zona/Ciudad/Provincia"
                className="form-control field rounded-0 border mt-2"
                id="province"
                onChange={(e) => HandleChange(e)}
                required
              />
              <input
                type={"text"}
                placeholder="Código Postal"
                id="postalcode"
                onChange={(e) => HandleChange(e)}
                className="form-control field rounded-0 border mt-2"
                required
              />
            </div>

            <div className="dropdown mt-3 mb-3">
              <button
                className="form-control field rounded-0 border mt-2 text-start"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {homeObj.residence}
              </button>
              <ul
                className="dropdown-menu w-100"
                style={{ height: "200px", overflowY: "scroll" }}
              >
                {countries.map((elem, i) => {
                  return (
                    <li
                      key={i}
                      className="dropdown-item"
                      type="button"
                      onClick={(e) => setHome({ ...homeObj, residence: elem })}
                    >
                      {elem}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
        {first ? (
          <div>
            <div>
              <div className="d-flex">
                <label>Modalidad</label>
                {calculate && <CustomTooltip text="Tipo de envío a Venezuela">
                  <button
                    className="btn btn-outline-danger rounded-circle d-flex 
                       align-items-center justify-content-center p-0 ms-2"
                    type="button"
                    style={{
                      height: "17px",
                      width: "17px",
                      fontSize: "0.8rem",
                    }}
                  >
                    i
                  </button>
                </CustomTooltip>
                }
              </div>
              <div className="d-flex mt-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    style={{ border: "1.99px solid black" }}
                    onChange={() => {
                      setMode("Aéreo");
                      setPackages([0]);
                      calc.current = { total: "" };
                      document.getElementById("largo").value = "";
                      document.getElementById("ancho").value = "";
                      document.getElementById("alto").value = "";
                      document.getElementById("peso").value = "";
                      document.getElementById("total").value = "";

                      Calculate(packages);
                    }}
                    checked={mode === "Aéreo" && true}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Aéreo
                  </label>
                </div>
                <div className="form-check ms-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    style={{ border: "1.99px solid black" }}
                    onChange={() => {
                      setMode("Marítimo");
                      setPackages([0]);
                      calc.current = { total: "" };
                      document.getElementById("largo").value = "";
                      document.getElementById("ancho").value = "";
                      document.getElementById("alto").value = "";
                      document.getElementById("peso").value = "";
                      if (document.getElementById("total")) {
                        document.getElementById("total").value = "";
                      }

                      Calculate(packages);
                    }}
                    checked={mode === "Marítimo" && true}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Marítimo
                  </label>
                </div>
              </div>
            </div>
            <div className="packages">
              {packages.map((e, i) => {
                return (
                  <div key={i}>
                    <p className="mt-3 ">Paquete {i + 1}</p>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                      <input
                        type="number"
                        className="form-control rounded"
                        placeholder="Largo"
                        id="largo"
                        value={calc.current[`dimension${i + 1 + ""}`]?.largo}
                        onInput={(e) => {
                          setInputValues({});
                          calc.current[`dimension${i + 1 + ""}`] = {
                            ...calc.current[`dimension${i + 1 + ""}`],
                            largo: e.target.value,
                          };
                          Calculate(packages);
                        }}
                        required
                      />
                      <input
                        className="form-control ms-3 rounded"
                        placeholder="Ancho"
                        type="number"
                        id="ancho"
                        value={calc.current[`dimension${i + 1 + ""}`]?.ancho}
                        onInput={(e) => {
                          setInputValues({});
                          calc.current[`dimension${i + 1 + ""}`] = {
                            ...calc.current[`dimension${i + 1 + ""}`],
                            ancho: e.target.value,
                          };
                          Calculate(packages);
                        }}
                        required
                      />
                      <input
                        className="form-control ms-3 me-2 rounded"
                        placeholder="Alto"
                        id="alto"
                        type="number"
                        value={calc.current[`dimension${i + 1 + ""}`]?.alto}
                        onInput={(e) => {
                          setInputValues({});
                          calc.current[`dimension${i + 1 + ""}`] = {
                            ...calc.current[`dimension${i + 1 + ""}`],
                            alto: e.target.value,
                          };
                          Calculate(packages);
                        }}
                        required
                      />
                      <span style={{ marginRight: "2px" }}>CM</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="w-50">
                        <label className="">Peso aproximado</label>
                        <div className="d-flex align-items-center w-100">
                          <input
                            type="number"
                            className="form-control me-2 rounded"
                            id="peso"
                            value={calc.current[`dimension${i + 1 + ""}`]?.peso}
                            onInput={(e) => {
                              setInputValues({});
                              calc.current[`dimension${i + 1 + ""}`] = {
                                ...calc.current[`dimension${i + 1 + ""}`],
                                peso: e.target.value,
                              };
                              Calculate(packages);
                            }}
                            required
                          />{" "}
                          KG
                        </div>
                      </div>
                    </div>
                    {i !== 0 && (
                      <AiFillDelete
                        className="highlight mt-2"
                        size={26}
                        cursor={"pointer"}
                        onClick={() => DeletePackage(i)}
                      />
                    )}

                    {i === packages.length - 1 && (
                      <div className="d-flex align-items-center mt-2">
                        <MdAddCircle
                          className="highlight"
                          size={26}
                          cursor={"pointer"}
                          onClick={() =>
                            setPackages([
                              ...packages,
                              packages[packages.length - 1] + 1,
                            ])
                          }
                        />
                        <p className="ms-2">Añadir paquete</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            {!advanced && (
              <div className="mt-3">
                <div className="d-flex align-items-start">
                  <label>Total a pagar</label>
                  <CustomTooltip text="No incluye Impuestos ni Seguro">
                    <button
                      className="btn btn-outline-danger rounded-circle d-flex 
                       align-items-center justify-content-center p-0 ms-2"
                      type="button"
                      style={{
                        height: "17px",
                        width: "17px",
                        fontSize: "0.8rem",
                      }}
                    >
                      i
                    </button>
                  </CustomTooltip>
                </div>
                <div
                  className="d-flex mt-1"
                  style={{ position: "relative", width: "100%" }}
                >
                  <input
                    type="text"
                    className="form-control bg-white rounded"
                    id="total"
                    value={calc.current.total}
                    disabled={true}
                    required
                  />
                  <div className="curr">EUR</div>
                </div>
              </div>
            )}

            {advanced && (
              <>
                <div className="w-100 mt-3">
                  <div className="d-flex">
                    <label className="">Valor (EUR)</label>
                    <CustomTooltip text="Valor Total de toda tu carga.">
                      <button
                        className="btn btn-outline-danger rounded-circle d-flex 
                       align-items-center justify-content-center p-0 ms-2"
                        type="button"
                        style={{
                          height: "17px",
                          width: "17px",
                          fontSize: "0.8rem",
                        }}
                      >
                        i
                      </button>
                    </CustomTooltip>
                  </div>
                  <div className="d-flex align-items-center rounded">
                    <input className="form-control me-2" type="text" required
                      value={valor}
                      onChange={(e) => setValor(e.target.value)} />
                    {/* EUR */}
                  </div>
                </div>
                <div className="w-100 mt-3">
                  <label className="">Contenido general</label>
                  <div className="d-flex align-items-center rounded">
                    <input
                      className="form-control me-2"
                      type="text"
                      id="content"
                      placeholder="E.j: Tablet, Zapatos, Alimentos ..."
                      value={calc.current?.content}
                      onInput={(e) => {
                        setInputValues({});
                        calc.current.content = e.target.value;
                        Calculate(packages);
                      }}
                      required
                    />
                  </div>
                  {mode === "Aéreo" && (
                    <p className="small text-danger">
                      Prohibido en modalidad Aérea los siguientes artículos:
                      Perfumes, Colonias, Yesqueros, Dinero en efectivo, Envases
                      presurizados o de spray, Material inflamable y Sustancias
                      estupefacientes.
                    </p>
                  )}
                </div>

                <div className="mt-3">
                  <label className="">
                    Seguro de Contenido (3% sobre Valor del Contenido)
                  </label>
                  <div className="d-flex align-items-center rounded mt-1">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="insurance"
                        id="insurance1"
                        style={{ border: "1.99px solid black" }}
                        checked={insurance}
                        onChange={() => setInsurance(true)}
                      />
                      <label className="form-check-label" htmlFor="insurance1">
                        Si
                      </label>
                    </div>
                    <div className="form-check ms-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="insurance"
                        id="insurance2"
                        style={{ border: "1.99px solid black" }}
                        checked={!insurance}
                        onChange={() => setInsurance(false)}
                      />
                      <label className="form-check-label" htmlFor="insurance2">
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <div className="d-flex">
                    <label className="">Nombre y Apellido Destinatario</label>
                    <input
                      className="form-check-input ms-4"
                      type="checkbox"
                      name="terms"
                      id="mismo"
                      style={{ border: "1.99px solid black" }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setReceiver(userdata.name + " " + userdata.surname);
                          setReceiverID(userdata.docId);
                          setReceiverP(userdata.phone);
                          setReceiverP2(userdata?.phone2);
                        } else {
                          e.target.checked = false;
                          setReceiver("");
                          setReceiverID("");
                          setReceiverP("");
                          setReceiverP2("");
                        }
                      }}
                    />
                    <label className="form-check-label ms-2" htmlFor="mismo">
                      Soy yo mismo
                    </label>
                  </div>
                  <div className="d-flex align-items-center rounded">
                    <input
                      className="form-control me-2"
                      type="text"
                      id="receiver"
                      placeholder=""
                      value={receiver}
                      onInput={(e) => setReceiver(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <label className="">Cédula Destinatario</label>
                  <div className="d-flex align-items-center rounded">
                    <input
                      className="form-control me-2"
                      type="text"
                      id="receiverID"
                      placeholder=""
                      value={receiverID}
                      onInput={(e) => setReceiverID(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center mt-3">
                  <div className="">
                    <label className="">Teléfono Destinatario</label>
                    <div className="d-flex align-items-center rounded">
                      <input
                        className="form-control me-2"
                        type="text"
                        id="receiverP"
                        placeholder=""
                        value={receiverP}
                        onInput={(e) => setReceiverP(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="ms-3">
                    <label className="">Teléfono adicional</label>
                    <div className="d-flex align-items-center rounded">
                      <input
                        className="form-control me-2"
                        type="text"
                        id="receiverP2"
                        placeholder=""
                        value={receiverP2}
                        onInput={(e) => setReceiverP2(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="terms"
                    id="acceptTerms"
                    style={{ border: "1.99px solid black" }}
                    checked={terms}
                    onChange={() => setTerms(!terms)}
                  />
                  <label className="form-check-label" htmlFor="acceptTerms">
                    Acepto los{" "}
                    <span
                      className="text-primary fw-bold"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#termsandconditions"
                    >
                      Términos y Condiciones
                    </span>{" "}
                    del servicio Postalven.
                  </label>
                </div>
              </>
            )}

            {advanced && (
              <>
                <label className="mt-3 ">Observaciones especiales:</label>
                <textarea
                  className="form-control rounded"
                  onChange={(e) => setRemarks(e.target.value)}
                  required={false}
                />
              </>
            )}

            {btn && (
              <button
                className="continue"
                style={{ width: small ? "auto" : "100%" }}
                type="Submit"
                disabled={loading}
              >
                {loading ? "Enviando" : "Continuar"}
              </button>
            )}
          </div>
        ) : (
          <div className="mt-3">
            {status?.length === 0 ? (
              <>
                {" "}
                <label>Número de Órden</label>
                <input
                  className="form-control mt-2 rounded"
                  onChange={(e) => setId(e.target.value)}
                  required
                />
                <p onClick={CheckStatus} className="continue" type="button">
                  BUSCAR
                </p>
              </>
            ) : (
              <div className="text-center">
                <p
                  className={`badge rounded-pill fs-6 py-2 px-3 ${CheckColor(
                    status
                  )}`}
                >
                  {status.toUpperCase()}
                </p>
                <button onClick={() => setStatus("")} className="continue">
                  Volver
                </button>
              </div>
            )}
          </div>
        )}
      </form>

      <div
        className="modal fade"
        id="termsandconditions"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: 30000000 }}
      >
        <div className="modal-dialog" style={{ zIndex: 20000000 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Términos y Condiciones
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeterms"
              ></button>
            </div>
            <div className="modal-body">
              <p style={{
                marginRight: "0",
                marginBottom: ".0001pt",
                fontSize: "16px",
                marginTop: "12.0pt",
                textAlign: "center",
                background: "white"
              }}>
                <span style={{}}>CONDICIONES GENERALES DEL SERVICIO DE ENV&Iacute;OS POSTALVEN</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '.0001pt',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Times New Roman, serif',
                marginTop: '12.0pt',
                background: 'white',
              }}><span style={{}}>1. Declaro que el contenido y valor de este bulto es real, que las mercanc&iacute;as consignadas son de mi absoluta propiedad y posesi&oacute;n, que sobre ellas no pesa ning&uacute;n gravamen, que no han sido dadas en arrendamiento ni son objeto de pleitos pendientes y consta de los art&iacute;culos se&ntilde;alados en el formulario.</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '0.5rem',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Times New Roman, serif',
                marginTop: '12.0pt',
                background: 'white',
                boxSizing: 'border-box',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                orphans: 2,
                textAlign: 'start',
                widows: 2,
                WebkitTextStrokeWidth: '0px',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                textDecorationColor: 'initial',
                wordSpacing: '0px',
              }}><span style={{}}>2. La Empresa se reserva el derecho de abrir, inspeccionar y reembalar todos los paquetes antes del embarque sin notificaci&oacute;n previa, por motivos de seguridad, de aduanas o por otros motivos de regulaci&oacute;n.</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '0.5rem',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Times New Roman, serif',
                marginTop: '12.0pt',
                background: 'white',
                boxSizing: 'border-box',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                orphans: 2,
                textAlign: 'start',
                widows: 2,
                WebkitTextStrokeWidth: '0px',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                textDecorationColor: 'initial',
                wordSpacing: '0px',
              }}><span style={{}}>3. La Empresa no se hace responsable por retrasos si alg&uacute;n dato en el formulario es incorrecto o ante la imposibilidad de contactar con el remitente o destinatario.</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '0.5rem',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Times New Roman, serif',
                marginTop: '12.0pt',
                background: 'white',
                boxSizing: 'border-box',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                orphans: 2,
                textAlign: 'start',
                widows: 2,
                WebkitTextStrokeWidth: '0px',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                textDecorationColor: 'initial',
                wordSpacing: '0px',
              }}><span style={{}}>4. La Empresa no se hace responsable por retrasos, p&eacute;rdidas o da&ntilde;os derivados de circunstancias ajenas a su control, que incluyen, pero no se limitan a las siguientes: Aduanas y otros representantes gubernamentales, disturbios, embargos, guerras, conmoci&oacute;n civil o acciones sindicales.</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '0.5rem',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Calibri, sans-serif',
                marginTop: '12.0pt',
                fontSize: '11.0pt',
                boxSizing: 'border-box',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                orphans: 2,
                textAlign: 'start',
                widows: 2,
                WebkitTextStrokeWidth: '0px',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                textDecorationColor: 'initial',
                wordSpacing: '0px',
                fontSize: '11.0pt',
              }}><span style={{}}>5. <span style={{
                background: "#F8FAFC",
                fontSize: '16px',
              }}>Los cargos de Env&iacute;o se calculan en funci&oacute;n del peso real o volum&eacute;trico, aplic&aacute;ndose el mayor.</span></span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '.0001pt',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Calibri, sans-serif',
                marginTop: '12.0pt',
                fontSize: '11.0pt',
              }}><span style={{
                fontFamily: 'Arial, sans-serif',
                background: '#F8FAFC',
                fontSize: '11.0pt',
              }}>6.&nbsp;</span><span style={{ fontSize: '16px', }}>En las expediciones A&eacute;reas se aplicar&aacute;n 3,00&euro; por Orden, en concepto de DUA (Documento &Uacute;nico Administrativo).</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '.0001pt',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Calibri, sans-serif',
                marginTop: '12.0pt',
                fontSize: '11.0pt',
              }}><span style={{ fontSize: '16px', }}>7. En las expediciones Mar&iacute;timas se aplicar&aacute; un Impuesto Arancelario del 38% sobre el valor declarado de cada paquete, siendo 38,00&euro; el importe m&iacute;nimo aplicable.</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '.0001pt',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Times New Roman, serif',
                marginTop: '12.0pt',
                background: 'white',
              }}><span style={{ fontSize: '16px', }}>8. Peso m&aacute;ximo en Env&iacute;os A&eacute;reos: 20Kg por paquete. La Empresa repartir&aacute; el contenido de un Env&iacute;o A&eacute;reo en varios bultos si &eacute;ste excediera el l&iacute;mite.</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '0.5rem',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Times New Roman, serif',
                marginTop: '12.0pt',
                background: 'white',
                boxSizing: 'border-box',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                orphans: 2,
                textAlign: 'start',
                widows: 2,
                WebkitTextStrokeWidth: '0px',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                textDecorationColor: 'initial',
                wordSpacing: '0px',
              }}><span style={{}}>9. Prohibido en modalidad A&eacute;rea los siguientes art&iacute;culos: Perfumes, Colonias, Yesqueros, Dinero en efectivo, Envases presurizados o de spray, Material inflamable y Sustancias estupefacientes.</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '0.5rem',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Times New Roman, serif',
                marginTop: '12.0pt',
                background: 'white',
                boxSizing: 'border-box',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                orphans: 2,
                textAlign: 'start',
                widows: 2,
                WebkitTextStrokeWidth: '0px',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                textDecorationColor: 'initial',
                wordSpacing: '0px',
              }}><span style={{}}>10. La Empresa har&aacute; cuanto razonablemente est&eacute; a su alcance para entregar el env&iacute;o de acuerdo a los tiempos de tr&aacute;nsito habituales, pero estos tiempos de tr&aacute;nsito no son vinculantes y no forman parte del contrato.</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '0.5rem',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Times New Roman, serif',
                marginTop: '12.0pt',
                background: 'white',
                boxSizing: 'border-box',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                orphans: 2,
                textAlign: 'start',
                widows: 2,
                WebkitTextStrokeWidth: '0px',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                textDecorationColor: 'initial',
                wordSpacing: '0px',
              }}><span style={{}}>11. En caso de da&ntilde;os y/o robo en el contenido del env&iacute;o, el seguro cubrir&aacute; la cantidad total o parcial seg&uacute;n el caso, siempre y cuando el paquete sea revisado e inspeccionado al momento de la entrega y en presencia del repartidor o transportista en todo momento, expresando su disconformidad por escrito en el recibo.</span></p>
              <p style={{
                marginRight: '0cm',
                marginBottom: '0.5rem',
                marginLeft: '0cm',
                fontSize: '16px',
                fontFamily: 'Times New Roman, serif',
                marginTop: '12.0pt',
                background: 'white',
                boxSizing: 'border-box',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                orphans: 2,
                textAlign: 'start',
                widows: 2,
                WebkitTextStrokeWidth: '0px',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                textDecorationColor: 'initial',
                wordSpacing: '0px',
              }}><span style={{}}>12. Al contratar los servicios de Postalven, usted, en calidad de cliente Remitente, acuerda, en su nombre o representaci&oacute;n y en nombre del destinatario del env&iacute;o y de cualquier persona interesada en el env&iacute;o, que se aplicar&aacute;n estos T&eacute;rminos y Condiciones generales, adem&aacute;s de lo expuesto en
                  {" "}<a href="https://postalven.com/terms" target="_blank"
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      background: '#F8FAFC',
                      borderBox: 'border-box',
                      color: 'rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1))'
                    }}>
                    <span style={{ color: "windowtext" }}>www.postalven.com/terms</span></a>.</span></p>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary py-2"
                onClick={() => {
                  document.getElementById("closeterms").click();
                  setTerms(false);
                }}
              >
                Cerrar
              </button>
              <button
                type="button"
                className="continue w-auto py-2 my-0"
                onClick={() => {
                  document.getElementById("closeterms").click();
                  setTerms(true);
                }}
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAlert()(Calculator);
