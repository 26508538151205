import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AuthContextProvider from "./Contexts/AuthContext";
import CalcContextProvider from "./Contexts/CalcContext";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

// optional configuration
const options = {
  position: positions.MIDDLE,
  timeout: 6000,
  transition: transitions.SCALE,
  width: window.matchMedia("(min-width: 500px").matches ? "500px" : "300px",
  containerStyle: {
    zIndex: 600000000000,
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AlertProvider template={AlertTemplate} {...options} zindex={600000000000}>
    <AuthContextProvider>
      <CalcContextProvider>
        <App />
      </CalcContextProvider>
    </AuthContextProvider>
    </AlertProvider>
  </Router>
);
