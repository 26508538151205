import React, { useEffect, useState, useRef } from "react";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthContext } from "../Contexts/AuthContext";
import { Link, Navigate } from "react-router-dom";
import { AiFillBank } from "react-icons/ai";
import paypal from "../assets/paypal.png";
import flag from "../assets/flag.png";
import { withAlert } from "react-alert";


const OrderHistory = ({ alert }) => {
  const messageRef = useRef();
  const { userdata, user } = useAuthContext();
  const [orders, setOrders] = useState([]);
  const [cloading, setCloading] = useState(false);
  const [vloading, setvloading] = useState(false);
  const [rloading, setRloading] = useState(false);
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [order, setorder] = useState({});
  const [vene, setVene] = useState(false);

  const FetchOrders = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/orders/userorders",
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      setOrders(data.orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchOrders();
  }, []);

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
    }
  },
    [showBank])

  const Cancel = async (elem) => {
    try {
      setCloading(true);
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/orders/cancel",
        {
          id: elem._id,
          name: elem.name,
          email: userdata?.email,
          amount: elem?.amount,
          orderId: elem.orderId,
          fees: elem?.fees,
          date: elem?.date,
          receiver: elem?.receiver,
          receiverId: elem?.receiverId,
          accountNo: elem?.accountNo,
          Rphone: elem.Rphone,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      FetchOrders();
      setCloading(false);
    } catch (err) {
      console.log(err);
      setCloading(false);
    }
  };

  const RequestReceipt = async (detail) => {
    try {
      setRloading(true);
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/orders/receipt",
        {
          id: detail._id,
          name: detail.name,
          email: userdata?.email,
          date: detail.date,
          amount: detail.amount,
          orderId: detail.orderId,
          fees: detail.fees,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      setRloading(false);
      alert(
        "Tu solicitud de copia de factura ha sido enviada. La recibirás en tu email de 24 a 72 Hrs. hábiles"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const Open = async (elem) => {
    setorder(elem);

    if (elem.link) {
      const id = elem.link.match(/\/([^/]+)$/)[1];
      const { data } = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/pay/status",
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );

      setStatus(data.status);
    }

    setShow(true);
  };

  const CheckColor = (elem) => {
    let color = "";
    if (
      elem.toUpperCase() === "PENDIENTE" ||
      elem.toUpperCase() === "RECOGIENDO" ||
      elem.toUpperCase() === "ANULADA" ||
      elem.toUpperCase() === "PAGO PENDIENTE"
    ) {
      color = "red";
    } else if (elem.toUpperCase() === "ADUANAS") {
      color = "yellow";
    } else if (
      elem.toUpperCase() === "ENTREGADO" ||
      elem.toUpperCase() === "DISPONIBLE"
    ) {
      color = "green";
    } else {
      color = "blue";
    }

    return color;
  };

  const PaypalFees = (amount) => {
    const fees =
      (amount
        ?.replaceAll(".", "dd")
        .replaceAll(",", "cc")
        .replaceAll("dd", "")
        .replaceAll("cc", ".")
        .replaceAll("€", "") *
        3.5) /
      100;
    let total = (
      Number(
        amount
          ?.replaceAll(".", "dd")
          .replaceAll(",", "cc")
          .replaceAll("dd", "")
          .replaceAll("cc", ".")
          .replaceAll("€", "")
      ) + Number(fees)
    ).toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return amount ? total : "";
  };

  const PaymentVenezuela = async () => {
    setvloading(true)
    await axios.post(
      process.env.REACT_APP_BASE_URL + "/api/orders/venezuela",
      {
        orderId: order.orderId,
        name: order.name,
        email: order.email,
        type: order.type
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );

    setvloading(false)
    setVene(false)
    alert.show(<span className="small">¡MENSAJE ENVIADO!</span>, {
      type: "success",
    });
  }

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  useEffect(() => {
    if (show) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.height = "auto";
    }
  }, [show]);

  if (!user) return <Navigate to={"/login"} />;

  return (
    <>
      <PageBanner title={"Historial de Envíos"} />
      <div className="Orderhistory table-responsive canvas">
        <table className="table  mt-10 bg-white rounded shadow">
          <div className="filters"></div>
          <thead className="text-white bg-dark">
            <tr>
              <th scope="col">Orden</th>
              {window.matchMedia("(min-width: 55s0px)").matches && (
                <>
                  <th scope="col">Fecha</th>
                  <th scope="col">Modalidad</th>
                </>
              )}
              <th scope="col">Estado</th>
              <th>Ver</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((elem) => {
              return (
                <tr key={elem.orderId}>
                  <td>PQ{elem.orderId}</td>
                  {window.matchMedia("(min-width: 550px)").matches && (
                    <>
                      <th scope="row">{elem.date}</th>
                      <td>{elem.modality}</td>
                    </>
                  )}
                  <td>
                    <p
                      className={`badge rounded-pill py-2 px-3 ${CheckColor(
                        elem.status
                      )}`}
                    >
                      {elem.status.toUpperCase()}
                    </p>
                  </td>
                  <td className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary w-auto mt-0 fs-6 py-1 me-2"
                      onClick={() => Open(elem)}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Detalles
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {show && (
          <div
            className="modal_bg d-flex justify-content-center 
          align-items-center"
          >
            <div
              className="modal"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Detalles # PQ{order.orderId}
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShow(false);
                        setStatus("");
                        setShowBank(false);
                      }}
                    ></button>
                  </div>
                  {order.type !== "Tienda Online" ? (
                    <div className="modal-body">
                      <p className="fs-5 fw-bold text-center">{order.type}</p>
                      <div className="d-flex flex-wrap mt-1">
                        <div
                          style={{
                            width: window.matchMedia("(max-width: 750px)")
                              .matches
                              ? "100%"
                              : "50%",
                          }}
                        >
                          <p className="">Orden : PQ{order.orderId}</p>
                          <p className=" mt-1">
                            Estado:{" "}
                            <span
                              className={`badge rounded-pill py-1 px-3 ms-1 ${CheckColor(
                                order.status
                              )}`}
                            >
                              {order.status}{" "}
                            </span>
                          </p>
                          <p className=" mt-1">Fecha : {order.date}</p>
                          <p className=" mt-1">Modalidad: {order.modality}</p>
                          <p className="mt-1">Tienda : {order.company}</p>
                          <p className="mt-1 ">
                            Referencia de envio : {order.shipmentID}
                          </p>
                          <p
                            className="mt-1 "
                            style={{
                              whiteSpace: "normal",
                              overflowWrap: "anywhere",
                              width: window.matchMedia("(max-width: 650px)")
                                .matches
                                ? "100%"
                                : "50%",
                            }}
                          >
                            Contenido : {order.content}
                          </p>
                          <p className=" mt-1">Valor: {order.valor}</p>
                          <p className=" mt-1">
                            Seguro de Contenido:
                            <span className="ms-2">
                              {order.insurance === "true" ? "Si" : "No"}
                            </span>
                          </p>
                          <p className=" mt-1">Total Bultos : {order.Bultos}</p>
                          <p className=" mt-1">
                            Volumen : {Number(order.volume)?.toFixed(3)}
                          </p>
                          <p className=" mt-1">
                            Observaciones especiales: <br />
                            {order.remarks}
                          </p>
                          <p className=" mt-1">Factura: {order.total}</p>
                        </div>
                        <div className="w-50">
                          <p className="mt-1">
                            Nombre y Apellido Destinatario : {order.receiver}
                          </p>
                          <p className="mt-1">
                            Cédula Destinatario : {order.receiverID}
                          </p>
                          <p className="mt-1">
                            Teléfono Destinatario : {order.receiverP}
                          </p>
                          <p className="mt-1">
                            Teléfono adicional : {order.receiverP2}
                          </p>
                          <p className="mt-1">Agencia : {userdata.agency} </p>

                        </div>
                      </div>

                      <div className="mt-2">
                        {order.dimensions.map((elem, i) => {
                          const Orderdimen = elem[0];

                          return (
                            <div>
                              <p className="mb-2 fw-bold">Paquete {i + 1}</p>

                              <div className="d-flex">
                                <p className="w-50">
                                  Largo CM: {Orderdimen.largo}
                                </p>
                                <p className="w-50">
                                  Ancho CM: {Orderdimen.ancho}
                                </p>
                                <p className="w-50">
                                  Alto CM: {Orderdimen.alto}
                                </p>
                                <p className="w-50">
                                  Peso KG: {Orderdimen.peso}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {order.home && Object.keys(order.home).length > 1 && (
                        <>
                          <p className="highlight fw-bold fs-5 mt-3 ">
                            Domicilio para Recogidas:
                          </p>
                          <div className="d-flex flex-wrap align-items-center mt-1">
                            <p
                              className=" mt-1"
                              style={{
                                width: window.matchMedia("(max-width: 650px)")
                                  .matches
                                  ? "100%"
                                  : "50%",
                              }}
                            >
                              Residence: {order.home.residence}
                            </p>
                            <p
                              className=" mt-1"
                              style={{
                                width: window.matchMedia("(max-width: 650px)")
                                  .matches
                                  ? "100%"
                                  : "50%",
                              }}
                            >
                              Vía: {order.home.homevia}
                            </p>
                            <p
                              className=" mt-1"
                              style={{
                                width: window.matchMedia("(max-width: 650px)")
                                  .matches
                                  ? "100%"
                                  : "50%",
                              }}
                            >
                              Número: {order.home.homenumber}
                            </p>
                            <p
                              className=" mt-1"
                              style={{
                                //
                                width: window.matchMedia("(max-width: 650px)")
                                  .matches
                                  ? "100%"
                                  : "50%",
                              }}
                            >
                              Zona/Ciudad/Provincia: {order.home.province}
                            </p>
                            <p
                              className=" mt-1"
                              style={{
                                width: window.matchMedia("(max-width: 650px)")
                                  .matches
                                  ? "100%"
                                  : "50%",
                              }}
                            >
                              Código Postal: {order.home.postalcode}
                            </p>
                          </div>
                        </>
                      )}
                      {order.payment === "false" ? (
                        <button
                          className={`${order.status.toLowerCase() !== "recogiendo" &&
                            order.status.toLowerCase() !== "pendiente"
                            ? "continue"
                            : "continue bg-secondary"
                            } mt-3 w-auto`}
                          disabled={
                            order.status.toLowerCase() === "recogiendo" ||
                              order.status.toLowerCase() === "pendiente"
                              ? true
                              : false
                          }
                          onClick={() => {
                            setShowBank(true)
                            var scrollableDiv = document.getElementById("exampleModal");
                            scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
                          }}
                        >
                          Pagar Factura
                        </button>
                      ) : (
                        <button
                          className="mt-3 btn btn-success py-2 px-3"
                          disabled={true}
                        >
                          PAGADA
                        </button>
                      )}

                      {(order.status.toLowerCase() !== "recogiendo" ||
                        order.status.toLowerCase() !== "pendiente") &&
                        showBank && (
                          <>
                            <div className="d-flex mt-3">
                              <div className="">
                                <div className="d-flex align-items-start mb-2">
                                  <div className="bg-danger pay_icon">
                                    <AiFillBank size={25} color="white" />
                                  </div>
                                  <p className="ms-3 mb-0">
                                    Transferencia bancaria
                                  </p>
                                </div>
                                <p>TITULAR: POSTALVEN ENVíOS</p>
                                <p>BANCO: BBVA</p>
                                <p>IBAN: ES91 0182 0761 2002 0179 7309</p>
                                <p>CONCEPTO: "PQ{order.orderId}"</p>
                                <p>
                                  Factura:
                                  <span className="fw-bold">
                                    € {order.total?.replace("€", "")}{" "}
                                  </span>
                                </p>
                              </div>
                              <div
                                className="ms-4"
                                style={{ marginTop: "-15px" }}
                              >
                                <div className="d-flex align-items-center mb-1">
                                  <img src={paypal} height={60} />
                                </div>
                                <p>Se aplica 3.5% comisión</p>
                                <p>CONCEPTO: "PQ{order.orderId}"</p>
                                <p>info.es@postalven.com</p>
                                <p>
                                  Factura:{" "}
                                  <span className="fw-bold">
                                    € {PaypalFees(order.total)}
                                  </span>
                                </p>
                              </div>
                              <div
                                className="ms-4"
                                style={{ marginTop: "-15px" }}
                              >
                                <div className="d-flex align-items-center mb-1">
                                  <img src={flag} height={40} />
                                  <p
                                    className="ms-2"
                                    type="button"
                                    style={{ textDecoration: "underline" }}
                                    onClick={() => setVene(true)}
                                  >
                                    Quiero pagar en Venezuela
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="small mt-3 text-danger text-center">INFORMACIÓN: No hace falta informar sobre el pago. Verás tu orden PAGADA cuando confirmemos tu ingreso.</p>
                          </>
                        )}
                      <div ref={messageRef}></div>
                    </div>
                  ) : (
                    <div className="modal-body">
                      <div className="prod_details">
                        <p className="">
                          Orden: PQ{order.orderId}
                        </p>
                        <div className="d-flex align-items-center mt-1">
                          <p className="">
                            Estado:
                          </p>
                          <p
                            className={`ms-2 badge rounded-pill py-1 px-3 ${CheckColor(
                              order.status
                            )}`}
                          >
                            {order.status.toUpperCase()}
                          </p>
                        </div>
                        <p className="mt-1">
                          Fecha: {order.date}
                        </p>
                        <p className="mt-1">
                          Modalidad: {order.modality}
                        </p>
                        <p className="mt-1">
                          Contenido: {order.description.name.toUpperCase()}
                        </p>
                        <p className="mt-1">
                          Total Bultos: {order.description.quantity}
                        </p>
                        <p className="mt-1">
                          Seguro de Contenido:
                          <span className="ms-2">
                            {order.insurance === "true" ? "Si" : "No"}
                          </span>
                        </p>
                        <p className="mt-1">
                          Nombre y Apellido Destinatario : {order.receiver}
                        </p>
                        <p className="mt-1">
                          Cédula Destinatario : {order.receiverID}
                        </p>
                        <p className="mt-1">
                          Teléfono Destinatario : {order.receiverP}
                        </p>
                        <p className="mt-1">
                          Teléfono adicional : {order.receiverP2}
                        </p>
                        <p className="mt-1">
                          Agencia : {order.agency}
                        </p>
                        <p className="mt-1">
                          Factura: € {order.description.price?.replace("€", "")}
                        </p>

                        {order.payment === "false" ? (
                          <button
                            className={`${order.status.toLowerCase() !== "recogiendo" &&
                              order.status.toLowerCase() !== "pendiente"
                              ? "continue"
                              : "continue bg-secondary"
                              } mt-3 w-auto`}
                            disabled={
                              order.status.toLowerCase() === "recogiendo" ||
                                order.status.toLowerCase() === "pendiente"
                                ? true
                                : false
                            }
                            onClick={() => {
                              setShowBank(true)
                            }}
                          >
                            Pagar Factura
                          </button>
                        ) : (
                          <button
                            className="mt-3 btn btn-success py-2 px-3"
                            disabled={true}
                          >
                            PAGADA
                          </button>
                        )}

                        {(order.status.toLowerCase() !== "recogiendo" ||
                          order.status.toLowerCase() !== "pendiente") &&
                          showBank && (
                            <>
                              <div className="d-flex mt-3">
                                <div className="">
                                  <div className="d-flex align-items-start mb-2">
                                    <div className="bg-danger pay_icon">
                                      <AiFillBank size={25} color="white" />
                                    </div>
                                    <p className="ms-3 mb-0">
                                      Transferencia bancaria
                                    </p>
                                  </div>
                                  <p>TITULAR: POSTALVEN ENVíOS</p>
                                  <p>BANCO: BBVA</p>
                                  <p>IBAN: ES91 0182 0761 2002 0179 7309</p>
                                  <p>CONCEPTO: "PQ{order.orderId}"</p>
                                  <p>
                                    Factura:
                                    <span className="fw-bold">
                                      €{" "}
                                      {order?.description.price?.replace("€", "")}{" "}
                                    </span>
                                  </p>
                                </div>
                                <div
                                  className="ms-4"
                                  style={{ marginTop: "-15px" }}
                                >
                                  <div className="d-flex align-items-center mb-1">
                                    <img src={paypal} height={60} />
                                  </div>
                                  <p>Se aplica 3.5% comisión</p>
                                  <p>CONCEPTO: "PQ{order.orderId}"</p>
                                  <p>info.es@postalven.com</p>
                                  <p>
                                    Factura:{" "}
                                    <span className="fw-bold">
                                      €{" "}
                                      {PaypalFees(
                                        order?.description.price?.replace("€", "")
                                      )}
                                    </span>
                                  </p>
                                </div>

                                <div
                                  className="ms-4"
                                  style={{ marginTop: "-15px" }}
                                >
                                  <div className="d-flex align-items-center mb-1">
                                    <img src={flag} height={40} />
                                    <p
                                      className="ms-2"
                                      type="button"
                                      style={{ textDecoration: "underline" }}
                                      onClick={() => setVene(true)}
                                    >
                                      Quiero pagar en Venezuela
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <p className="small mt-3 text-danger text-center">INFORMACIÓN: No hace falta informar sobre el pago. Verás tu orden PAGADA cuando confirmemos tu ingreso.</p>
                            </>
                          )}
                      </div>
                      <div
                        ref={messageRef}></div>
                    </div>
                  )}
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setShow(false);
                        setStatus("");
                        setShowBank(false);
                      }}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {vene && <div
          className="modal_bg d-flex justify-content-center 
          align-items-center"
        >
          <div
            className="modal"
            id="venezuelaModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    PQ{order.orderId}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setVene(false)
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  <p className="fs-5 my-2">
                    Un Agente contactará contigo en breve para indicarte el procedimiento para el pago en Venezuela.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn bg-p w-auto text-white"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      PaymentVenezuela()
                    }}
                    disabled={vloading}
                  >
                    {vloading ? "Enviando" : "Aceptar"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setVene(false)
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </>
  );
};

export default withAlert()(OrderHistory);
