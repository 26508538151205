import React from "react";
import { FaUser, FaHourglassEnd } from "react-icons/fa";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import one from "../assets/one.png";
import two from "../assets/two.png";
import three from "../assets/three.png";
import { Link } from "react-router-dom";

const HowWorks = () => {
  return (
    <div className="how_works mt-10 py-3">
      <div className="canvas canvas_col">
        <h1 className="text-center fw-bold">¿Cómo Funciona?</h1>

        <div className="grid mt-5">
          <div className="text-center Card">
            <img src={one} width={60} />
            <Link style={{textDecoration: "none", color: "black"}} to="/registro">
              <h2 className="fs-4 fw-bold mt-2">Abre tu Buzón Postalven</h2>
            </Link> 
            <p className="mt-2 font2">
            ¡Es gratis! y podrás empezar a enviar tus compras a Venezuela en minutos.
            </p>
          </div>
          <div className="text-center Card">
            <img src={two} width={60} />
            <h2 className="fs-4 fw-bold mt-2">Envíanos tus compras</h2>
            <p className="mt-2 font2">
            Estamos preparados para recibir tus compras online en USA o España.
            </p>
          </div>
          <div className="Card text-center">
            <img src={three} width={60} />
            <h2 className="fs-4 fw-bold mt-2">Recíbelo en Venezuela</h2>
            <p className="mt-2 font2">
            Más de 250 Agencias para recoger tus Envíos en todo el territorio Venezolano.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
