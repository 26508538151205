import "./App.css";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import Home from "./Pages/Home";
import Register from "./Pages/Register";
import About from "./Pages/About";
import Footer from "./Components/Footer";
import Contact from "./Pages/Contact";
import Profile from "./Pages/Profile";
import Login from "./Pages/Login";
import { useAuthContext } from "./Contexts/AuthContext";
import Forget from "./Pages/Forget";
import ResetPassword from "./Pages/ResetPassword";
import OrderHistory from "./Pages/OrderHistory";
import Chat from "./Components/Chat";
import Payment from "./Pages/Payment";
import TextPage from "./Pages/textPage";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import axios from "axios";
import Agencies from "./Pages/Agencies";
import Recogida from "./Pages/Recogida";
import Calcular from "./Pages/Calcular";
import Verify from "./Pages/Verify";
import Shop from "./Pages/Shop";
import ProductDetail from "./Pages/ProductDetail";

function App() {
  const [options, setOption] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { userdata, user, setCookies, cookies } = useAuthContext();
  const [show, setshow] = useState(true);

  return (
    <>
      {!cookies && location.pathname !== "/verify" && (
        <>
          {!options ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#2B373B",
                position: "fixed",
                bottom: "0%",
                zIndex: 5000000,
                color: "white",
                padding: "20px",
              }}
              className="cookie-main"
            >
              <div className="w-75 me-4 cookie-text">
                <p>
                  Usamos cookies y tecnologías similares para habilitar los
                  servicios y la funcionalidad de nuestro sitio web y para
                  comprender su interacción con nuestro servicio. Al hacer click
                  en Aceptar, se acepta que se utilicen dichas tecnologías para
                  marketing y estadísticas. <br />
                  <Link to="/cookies">Ver Política de Cookies.</Link>
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="btn btn-outline-light mt-3"
                  onClick={() => setOption(true)}
                >
                  Opciones de Cookies
                </button>
                <button
                  className="btn btn-light ms-3 mt-3"
                  onClick={() => {
                    Cookies.set("cookies", true, { expires: 360, secure: true });
                    setCookies(true);
                  }}
                >
                  Aceptar
                </button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
                background: "#2B373B",
                position: "fixed",
                bottom: "0%",
                zIndex: 5000000,
                color: "white",
                padding: "20px 40px",
                height: "100vh",
                overflowY: "scroll",
              }}
            >
              <div>
                <GrClose
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "30px",
                    filter: "invert(100%)",
                  }}
                  cursor={"pointer"}
                  size={30}
                  onClick={() => setOption(false)}
                />
                <h4 className="my-1">Configuración avanzada de cookies</h4>
                <div className="mt-2 d-flex align-items-start justify-content-between">
                  <div>
                    <p className="fs-5">Cookies esenciales</p>
                    <p>
                      {" "}
                      Estas cookies permiten funciones básicas como la
                      seguridad, la verificación de la identidad y la
                      administración de la red. Estas cookies no pueden ser
                      desactivadas.{" "}
                    </p>
                  </div>

                  <div className="form-check form-switch" type="button">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      style={{
                        width: "50px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      id="flexSwitchCheckDefault"
                      checked
                    />
                  </div>
                </div>
                <hr />
                <div className="mt-2 d-flex align-items-start justify-content-between">
                  <div>
                    <p className="fs-5">Habilitar las cookies de marketing</p>
                    <p>
                      {" "}
                      Estas cookies se utilizan para hacer un seguimiento de la
                      eficacia de la publicidad para proporcionar un servicio
                      más relevante y ofrecer mejores anuncios que se ajusten a
                      tus intereses.
                    </p>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      style={{
                        width: "50px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
                <hr />

                <div className="mt-2 d-flex align-items-start justify-content-between">
                  <div>
                    <p className="fs-5">Habilitar cookies funcionales</p>
                    <p>
                      {" "}
                      Estas cookies recopilan datos para recordar las elecciones
                      que los usuarios hacen, para mejorar y ofrecer una
                      experiencia más personalizada.
                    </p>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      style={{
                        width: "50px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
                <hr />

                <div className="mt-2 d-flex align-items-start justify-content-between">
                  <div>
                    <p className="fs-5">
                      Habilitar las cookies de estadísticas
                    </p>
                    <p>
                      {" "}
                      Estas cookies nos ayudan a entender cómo los visitantes
                      interactúan con nuestro sitio web, descubren errores y
                      proporcionan mejores estadísticas generales.
                    </p>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      style={{
                        width: "50px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
                <hr />

                <button
                  className="btn px-3 mb-4 btn-light d-block ms-auto"
                  onClick={() => {
                    Cookies.set("cookies", true);
                    setCookies(true);
                  }}
                >
                  Guardar
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {user && userdata && Object.values(userdata).length < 12 && (
        <div
          className="d-flex align-items-center justify-content-around 
          alert alert-danger w-100 mb-0 rounded-0"
          role="alert"
          style={{ zIndex: 2000000, height: "70px" }}
        >
          <span className="alert_text">
            ¡Aviso! Completa tus datos personales.
          </span>
          <p
            className="continue w-auto fs-6 px-4 py-2 mt-0"
            style={{ whiteSpace: "nowrap" }}
            target="_blank"
            type="button"
            onClick={() => navigate(`/profile?edit=true`)}
          >
            Completar
          </p>
        </div>
      )}

      {show && userdata && <Chat />}
      <Routes>
        {show ? (
          <>
            {/* <Route path="/verify/:id" element={<Verify />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/registro" element={<Register />} />
            <Route path="/forgotpassword" element={<Forget />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/login/:id" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/hacerenvio" element={<Payment />} />
            <Route path="/recogida" element={<Recogida />} />
            <Route path="/calcular" element={<Calcular />} />
            <Route path="/tienda" element={<Shop />} />
            <Route path="/tienda/:slug" element={<ProductDetail />} />


            <Route
              path="/privacy"
              element={
                <TextPage
                  title={"Política de Privacidad"}
                  text={
                    <>
                     <p>Pol&iacute;tica de privacidad </p>
<p>Objeto </p>
<p>La presente p&aacute;gina Web ha sido dise&ntilde;ada para dar a conocer los servicios ofertados por la empresa EURO DIGITAL MULTISERVICES SLU con CIF B-13852330, con domicilio social en C/ Garcilaso de la Vega 21, Local 12, 38005 Santa Cruz de Tenerife, España</p>
<p>Propiedad intelectual e industrial </p>
<p>Los derechos de propiedad intelectual de la p&aacute;gina WWW.POSTALVEN.COM, su c&oacute;digo fuente, dise&ntilde;o, estructuras de navegaci&oacute;n y los distintos elementos en ella contenidos son titularidad de EURO DIGITAL MULTISERVICES SLU, a quien corresponde el ejercicio exclusivo de los derechos de explotaci&oacute;n de los mismos en cualquier forma y, en especial, los derechos de reproducci&oacute;n, distribuci&oacute;n, comunicaci&oacute;n p&uacute;blica y transformaci&oacute;n, de acuerdo con la legislaci&oacute;n espa&ntilde;ola y de la Uni&oacute;n Europea aplicable.</p>
<p>Contenidos </p>
<p>Se facilita a trav&eacute;s de esta Web informaci&oacute;n acerca de servicios destinados a conocimiento p&uacute;blico que en todo caso se sujetar&aacute;n a los t&eacute;rminos y condiciones expresamente detallados en cada momento y que son accesibles desde esta p&aacute;gina Web, los cuales se sujetar&aacute;n a las distintas disposiciones legales de aplicaci&oacute;n.</p>
<p>Acceso y uso </p>
<p>Tanto el acceso a esta p&aacute;gina Web, como el uso que pueda hacerse de la informaci&oacute;n y contenidos incluidos en la misma, ser&aacute; de la exclusiva responsabilidad de quien lo realice. Las condiciones de acceso a este Web estar&aacute;n supeditadas a la legalidad vigente y los principios de la buena fe y uso l&iacute;cito por parte del usuario de la misma, quedando prohibido con car&aacute;cter general cualquier tipo de actuaci&oacute;n en perjuicio de EURO DIGITAL MULTISERVICES SLU. Se considerar&aacute; terminantemente prohibido el uso de la presente p&aacute;gina Web con fines ilegales o no autorizados.</p>
<p>Responsabilidad </p>
<p>EURO DIGITAL MULTISERVICES SLU no se hace responsable bajo ning&uacute;n concepto por ning&uacute;n tipo de da&ntilde;o que pudiesen ocasionar los Usuarios a la presente p&aacute;gina Web, o a cualquier otra, por el uso ilegal o indebido de la misma, o de los contenidos e informaciones accesibles o facilitadas a trav&eacute;s de ella.</p>
<p>Servicio </p>
<p>EURO DIGITAL MULTISERVICES SLU se reserva el derecho de suspender el acceso a su p&aacute;gina Web, sin previo aviso, de forma discrecional y temporal, por razones t&eacute;cnicas o de cualquier otra &iacute;ndole, pudiendo asimismo modificar unilateralmente tanto las condiciones de acceso, como la totalidad o parte de los contenidos en ella incluidos.</p>
<p>Generales </p>
<p>Para toda cuesti&oacute;n litigiosa o que incumba a la P&aacute;gina Web de WWW.POSTALVEN.COM, ser&aacute; de aplicaci&oacute;n la legislaci&oacute;n espa&ntilde;ola, siendo competentes para la resoluci&oacute;n de todos los conflictos derivados o relacionados con el uso de esta p&aacute;gina Web, los Juzgados y Tribunales del domicilio del usuario. El acceso a la p&aacute;gina Web de WWW.POSTALVEN.COM implica la aceptaci&oacute;n de todas las condiciones anteriormente expresadas.</p>
<p>Hiperenlaces </p>
<p>Los hiperenlaces contenidos en el sitio web de WWW.POSTALVEN.COM pueden dirigir a p&aacute;ginas web de terceros. WWW.POSTALVEN.COM no asume ninguna responsabilidad por el contenido, informaciones o servicios que pudieran aparecer en dichos sitios, que tendr&aacute;n exclusivamente car&aacute;cter informativo y que en ning&uacute;n caso implican relaci&oacute;n alguna entre WWW.POSTALVEN.COM y a las personas o entidades titulares de tales contenidos o titulares de los sitios donde se encuentren.</p>

                    </>
                  }
                />
              }
            />
            <Route
              path="/avisolegal"
              element={
                <TextPage
                  title={"Aviso Legal"}
                  text={
                    <>
                     <p>Aviso legal</p>
<p>Ley Protecci&oacute;n de Datos de Car&aacute;cter Personal </p>
<p>De conformidad con lo establecido en Reglamento (UE) 2016/679 del Parlamento Europeo de Protecci&oacute;n de Datos de Car&aacute;cter Personal, y de la Ley Org&aacute;nica 3/2018, de 5 de diciembre (LOPDGDD), se informa al Cliente/Usuario de que los datos personales que nos facilita ser&aacute;n incluidos en un Registro de Actividades titularidad de EURO DIGITAL MULTISERVICES SLU, con la finalidad de realizar una correcta gesti&oacute;n de la relaci&oacute;n comercial, as&iacute; como para informarle de novedades, productos y/o servicios tanto por medios escritos como electr&oacute;nicos.</p>
<p>Vd. podr&aacute; en cualquier momento revocar el consentimiento prestado para el env&iacute;o de comunicaciones comerciales electr&oacute;nicas remitiendo un correo a la direcci&oacute;n info.es@postalven.com</p>
<p>Finalmente, le informamos de que puede ejercer sus derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n y/u oposici&oacute;n, limitaci&oacute;n de tratamiento, y portabilidad, as&iacute; como revocaci&oacute;n del consentimiento prestado, dirigi&eacute;ndose por escrito a EURO DIGITAL MULTISERVICES SLU sita en C/ Garcilaso de la Vega 21, Local 12, 38005 Santa Cruz de Tenerife, España.</p>
<p>Ley de Servicios de la Informaci&oacute;n y Comercio Electr&oacute;nico </p>
<p>Seg&uacute;n la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y Comercio Electr&oacute;nico, se comunican los siguientes datos sobre el dominio de WWW.POSTALVEN.COM</p>
<p>Titular del Dominio </p>
<p>EURO DIGITAL MULTISERVICES SLU</p>
<p>CIF B-13852330</p>
<p>C/ Garcilaso de la Vega 21, Local 12, 38005 Santa Cruz de Tenerife, España</p>
<p>Correo electr&oacute;nico: info.es@postalven.com</p>
<p>Nombre del dominio</p>
<p>WWW.POSTALVEN.COM</p>

                    </>
                  }
                />
              }
            />
            <Route
              path="/cookies"
              element={
                <TextPage
                  title={"Política de Cookies"}
                  text={
                    <div>
                   <p>Cl&aacute;usula Cookies</p>
<p>En EURO DIGITAL MULTISERVICES SLU utilizamos cookies con el objetivo de prestar un mejor servicio y proporcionarte una mejor experiencia en tu navegaci&oacute;n. Queremos informarte de manera clara y precisa sobre las cookies que utilizamos, detallando a continuaci&oacute;n, que es una cookie, para que sirve, que tipos de cookies utilizamos, cuales son su finalidad y como puedes configurarlas o deshabilitarlas si as&iacute; lo deseas.</p>
<p>&iquest;Qu&eacute; es una cookie y para qu&eacute; sirve?</p>
<p>Una Cookie es un peque&ntilde;o archivo que se almacena en el ordenador del usuario, tablet, smartphone o cualquier otro dispositivo con informaci&oacute;n sobre la navegaci&oacute;n.</p>
<p>El conjunto de &laquo;cookies&raquo; de todos nuestros usuarios nos ayuda a mejorar la calidad de nuestra web, permiti&eacute;ndonos controlar qu&eacute; p&aacute;ginas son &uacute;tiles, cu&aacute;les no y cu&aacute;les son susceptibles de mejora.</p>
<p>Las cookies son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestaci&oacute;n de servicios interactivos, facilit&aacute;ndote la navegaci&oacute;n y usabilidad de nuestra web.</p>
<p>En ning&uacute;n caso las cookies podr&iacute;an da&ntilde;ar tu equipo. Por contra, el que est&eacute;n activas nos ayuda a identificar y resolver los errores.</p>
<p>&iquest;Qu&eacute; tipos de cookies utilizamos?&nbsp;</p>
<p>Seg&uacute;n la entidad que la gestiona:</p>
<p>Cookies propias: Son aquellas que se env&iacute;an a tu equipo desde nuestros propios equipos o dominios y desde el que prestamos el servicio que nos solicitas.</p>
<p>Cookies de terceros: Son aquellas que se env&iacute;an a tu equipo desde un equipo o dominio que no es gestionado por nosotros, sino por otra entidad colaboradora. Como, por ejemplo, las usadas por redes sociales, o por contenido externo como Google Maps.</p>
<p>Seg&uacute;n el plazo de tiempo que permanecen activadas:</p>
<p>Cookies de sesi&oacute;n: Son cookies temporales que permanecen en el archivo de cookies de tu navegador hasta que abandonas la p&aacute;gina web, por lo que ninguna queda registrada en el disco duro de tu ordenador. La informaci&oacute;n obtenida por medio de estas cookies, sirven para analizar pautas de tr&aacute;fico en la web. A la larga, esto nos permite proporcionar una mejor experiencia para mejorar el contenido y facilitar su uso.</p>
<p>Cookies persistentes: son almacenadas en el disco duro y nuestra web las lee cada vez que realizas una nueva visita. Una web permanente posee una fecha de expiraci&oacute;n determinada. La cookie dejar&aacute; de funcionar despu&eacute;s de esa fecha. Estas cookies las utilizamos, generalmente, para facilitar los servicios de compra y registro.</p>
<p>Seg&uacute;n su finalidad:</p>
<p>Cookies t&eacute;cnicas: Son aquellas necesarias para la navegaci&oacute;n y el buen funcionamiento de nuestra p&aacute;gina web. Permiten, por ejemplo, controlar el tr&aacute;fico y la comunicaci&oacute;n de datos, acceder a partes de acceso restringido, realizar el proceso de compra de un pedido, utilizar elementos de seguridad, almacenar contenidos para poder difundir v&iacute;deos o compartir contenidos a trav&eacute;s de redes sociales.</p>
<p>Cookies de personalizaci&oacute;n: Son aqu&eacute;llas que te permiten acceder al servicio con unas caracter&iacute;sticas predefinidas en funci&oacute;n de una serie de criterios, como por ejemplo el idioma, el tipo de navegador a trav&eacute;s del cual se accede al servicio, la configuraci&oacute;n regional desde donde se accede al servicio, etc.</p>
<p>Cookies de an&aacute;lisis: Son aqu&eacute;llas que nos permiten cuantificar el n&uacute;mero de usuarios y as&iacute; realizar la medici&oacute;n y an&aacute;lisis estad&iacute;stico de la utilizaci&oacute;n que hacen los usuarios de los servicios prestados. Para ello se analiza su navegaci&oacute;n en nuestra p&aacute;gina web con el fin de mejorar la oferta de productos y servicios que ofrecemos.</p>
<p>Cookies publicitarias: Son aqu&eacute;llas que permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios que se pudieran incluir en nuestra p&aacute;gina web.</p>
<p>Cookies de publicidad comportamental: Estas cookies almacenan informaci&oacute;n del comportamiento de los usuarios obtenida a trav&eacute;s de la observaci&oacute;n continuada. Gracias a ellas, podemos conocer los h&aacute;bitos de navegaci&oacute;n en internet y mostrarte publicidad relacionada con tu perfil de navegaci&oacute;n.</p>
<p>Finalidad de cada una de las cookies utilizadas:</p>
<p>A continuaci&oacute;n, detallamos las cookies utilizadas en nuestra web y la finalidad de las mismas.</p>
<p>Relaci&oacute;n de cookies utilizadas en el dominio WWW.POSTALVEN.COM:</p>
<p>NOMBRE: PHPSESSID</p>
<p>FINALIDAD: COOKIE DE SESI&Oacute;N</p>
<p>PROPIA/TERCEROS: PROPIA</p>
<p>EXCLU&Iacute;DA/NO EXCLUIDA DEL DEBER DE LA INFORMACI&Oacute;N Y CONSENTIMIENTO: NO EXCLU&Iacute;DA</p>
<p>Relaci&oacute;n de terceros prestadores de servicios de cookies en el dominio WWW.POSTALVEN.COM:</p>
<p>NOMBRE PROVEEDOR: GOOGLE</p>
<p>FINALIDAD: AN&Aacute;LISIS Y T&Eacute;CNICAS</p>
<p>DESCRIPCI&Oacute;N DE LA FINALIDAD DE LA COOKIE: RECOPILAR INFORMACI&Oacute;N, INCLUIDA LA IP DEL USUARIO, QUE SER&Aacute; TRANSMITIDA, TRATADA Y ALMACENADA POR GOOGLE EN LOS T&Eacute;RMINOS Y CONDICIONES FIJADOS EN LA WEB google.com</p>
<p>NOTA: Esta lista se actualizar&aacute; con la mayor celeridad posible a medida que cambien los servicios del sitio web ofrecidos en el mismo. Sin embargo, ocasionalmente durante esta actualizaci&oacute;n puede ser que la lista no incluya ya una cookie, aunque siempre se referir&aacute; a cookies con prop&oacute;sitos id&eacute;nticos a los registrados en esta lista.</p>
<p>&iquest;C&oacute;mo puedes configurar o deshabilitar tus cookies?</p>
<p>Puedes permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuraci&oacute;n de las opciones de tu navegador de Internet. En caso de que no permitas la instalaci&oacute;n de cookies en tu navegador es posible que no puedas acceder a algunos de los servicios y que tu experiencia en nuestra web pueda resultar menos satisfactoria.</p>

                    </div>
                  }
                />
              }
            />
            <Route
              path="/protection"
              element={
                <TextPage
                  title={"Protección de Datos"}
                  text={
                    <>
                      <p>Pol&iacute;tica de Protecci&oacute;n de datos</p>
<p>Finalidad del tratamiento</p>
<p>En funci&oacute;n del formulario que en cada caso complete el Usuario, los datos podr&aacute;n ser&nbsp;tratados con las siguientes finalidades:</p>
<p>Usuarios registrados: La informaci&oacute;n que nos proporcione en el formulario de&nbsp;registro se utiliza para la gesti&oacute;n de Usuarios de la p&aacute;gina web, con el fin de que pueda acceder a su perfil, consultar sus pedidos y realizar sus compras sin que tenga que introducir de nuevo sus datos identificativos (nombre, apellidos, email y tel&eacute;fono) y domicilio de entrega. Puede modificar la configuraci&oacute;n de su&nbsp;perfil siempre que quiera. EURO DIGITAL MULTISERVICES SLU no admitir&aacute; intentos de registros falsos o que suplanten la identidad de personas o empresas, el suministro de informaci&oacute;n falsa en el formulario de registro implicar&aacute; la baja autom&aacute;tica del Usuario. EURO DIGITAL MULTISERVICES SLU se reserva el derecho de verificar la informaci&oacute;n facilitada por el Usuario mediante comprobaci&oacute;n telef&oacute;nica.</p>
<p>Newsletter: En los casos en los que nos otorgue su consentimiento seg&uacute;n lo previsto en el art&iacute;culo 21 de la Ley de Servicios de la Sociedad de la&nbsp;Informaci&oacute;n, utilizaremos sus datos identificativos y de contacto para enviarle&nbsp;informaci&oacute;n sobre novedades de la p&aacute;gina web, noticias de inter&eacute;s, as&iacute; como&nbsp;promociones y ofertas de nuestros productos y servicios. Los canales que&nbsp;utilizamos habitualmente son: correo electr&oacute;nico, correo postal, tel&eacute;fono, SMS,&nbsp;mensaje Push, pero solo si usted nos presta su consentimiento, y teniendo&nbsp;siempre la posibilidad de darse de baja de manera c&oacute;moda y gratuita.</p>
<p>Contacto: Los datos personales que nos proporcione a trav&eacute;s del formulario de&nbsp;contacto se tratar&aacute;n &uacute;nicamente para atender su consulta o petici&oacute;n. La&nbsp;informaci&oacute;n recabada se limita a datos identificativos y de contacto, as&iacute; como&nbsp;datos relacionados con la naturaleza de la petici&oacute;n que nos dirija.</p>
<p>Formulario de reclamaciones: Los datos personales que nos proporcione a&nbsp;trav&eacute;s del formulario se tratar&aacute;n &uacute;nicamente para atender su reclamaci&oacute;n.</p>
<p>Formulario de devoluciones: Los datos personales que nos proporcione a&nbsp;trav&eacute;s del formulario se tratar&aacute;n para la evaluaci&oacute;n y gesti&oacute;n de la devoluci&oacute;n o&nbsp;cambio solicitado y la recogida del producto si fuera necesario.</p>
<p>Otras finalidades: La informaci&oacute;n facilitada se puede utilizar ocasionalmente&nbsp;para: Encuestas para mejorar nuestros servicios, gestionar las solicitudes de informaci&oacute;n, sugerencias y reclamaciones de clientes para su gesti&oacute;n y resoluci&oacute;n.</p>
<p>Plazo de conservaci&oacute;n</p>
<p>Los datos personales proporcionados se conservar&aacute;n durante el plazo correspondiente para cumplir con las obligaciones legales, mientras no se oponga al tratamiento&nbsp;o revoque el consentimiento.</p>
<p>Legitimaci&oacute;n</p>
<p>EURO DIGITAL MULTISERVICES SLU est&aacute; legitimado al tratamiento de datos&nbsp;personales, en base al consentimiento otorgado por el interesado mediante la firma o&nbsp;aceptaci&oacute;n de los pertinentes formularios, para uno o varios fines espec&iacute;ficos, tal y como recoge el art&iacute;culo 6.1. a) del Reglamento General de Protecci&oacute;n de datos personales.</p>
<p>Exactitud de los datos</p>
<p>Por otro lado, con el fin de que los datos obrantes en nuestros ficheros, inform&aacute;ticos y/o&nbsp;en papel, siempre correspondan a la realidad, se tratar&aacute; de mantener actualizados. De<br /> manera que, a estos efectos, el Usuario deber&aacute; realizar los cambios, directamente,&nbsp;cuando as&iacute; est&eacute; habilitado o comunic&aacute;ndose, por medio fehaciente, al &aacute;rea o&nbsp;departamento correspondiente de EURO DIGITAL MULTISERVICES SLU.</p>
<p>Destinatarios</p>
<p>Los datos personales no ser&aacute;n cedidos o comunicados a terceros, salvo en los supuestos&nbsp;necesarios para el desarrollo, control y cumplimiento de la/s finalidad/es expresada/s,<br /> en los supuestos previstos seg&uacute;n Ley.</p>
<p>Derechos de los usuarios</p>
<p>No obstante, el interesado de los datos personales en todo caso podr&aacute; ejercitar los&nbsp;derechos que le asisten, de acuerdo con el Reglamento General de Protecci&oacute;n de Datos,<br /> y que son:</p>
<p>Derecho a solicitar el acceso a los datos personales relativos al interesado,&nbsp;Derecho a solicitar su rectificaci&oacute;n o supresi&oacute;n, Derecho a solicitar la limitaci&oacute;n de su tratamiento, Derecho a oponerse al tratamiento, Derecho a la portabilidad de los datos.</p>
<p>El interesado podr&aacute; ejercitar tales derechos mediante solicitud acompa&ntilde;ada de una&nbsp;fotocopia de su D.N.I, y en la que especificar&aacute; cu&aacute;l de &eacute;stos solicita sea satisfecho,&nbsp;remitida a la direcci&oacute;n:</p>
<p>EURO DIGITAL MULTISERVICES SLU</p>
<p>CIF B-13852330</p>
<p>C/ Garcilaso de la Vega 21, Local 12, 38005 Santa Cruz de Tenerife, España</p>
<p>Correo electr&oacute;nico: info.es@postalven.com</p>
<p>En caso de considerar vulnerado su derecho a la protecci&oacute;n de datos personales, podr&aacute;&nbsp;interponer una reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos&nbsp;(www.agpd.es).</p>
<p>Medidas de seguridad</p>
<p>Finalmente se informa que EURO DIGITAL MULTISERVICES SLU, adoptar&aacute; en su sistema de informaci&oacute;n las medidas t&eacute;cnicas y organizativas adecuadas, dando&nbsp;cumplimiento al principio de responsabilidad proactiva, a fin de garantizar la seguridad&nbsp;y confidencialidad de los datos almacenados, evitando as&iacute;, su alteraci&oacute;n, p&eacute;rdida,&nbsp;tratamiento o acceso no autorizado; teniendo en cuenta el estado de la t&eacute;cnica, los&nbsp;costes de aplicaci&oacute;n, y la naturaleza, el alcance, el contexto y los fines del tratamiento,&nbsp;as&iacute; como riesgos de probabilidad y gravedad variables asociadas a cada uno de los&nbsp;tratamientos.</p>

                    </>
                  }
                />
              }
            />

            <Route
              path="/terms"
              element={
                <TextPage
                  title={"Términos y Condiciones"}
                  text={
                    <>
                     <p>T&eacute;rminos y Condiciones de Transporte Internacional</p>
<p>Al hacernos entrega de su env&iacute;o, en calidad de Remitente, acepta los T&eacute;rminos y Condiciones establecidos en el presente contrato de transporte en su propio nombre y de terceros haya firmado o no el albar&aacute;n. Igualmente acepta que podamos subcontratar la totalidad o parte del transporte. Ninguno de nuestros empleados, agentes o corresponsales est&aacute; autorizado para suspender, alterar o modificar estos T&eacute;rminos y Condiciones. No estaremos obligados por instrucciones verbales o escritas que entren en conflicto con estos T&eacute;rminos y Condiciones. Estos T&eacute;rminos y Condiciones ser&aacute;n de aplicaci&oacute;n a cualquier etiqueta producida por los sistemas automatizados de&nbsp;EURO DIGITAL MULTISERVICES SLU (en adelante&nbsp;Postalven) conocimiento de embarque a&eacute;reo o carta de porte que tendr&aacute;n la consideraci&oacute;n de Albar&aacute;n a todos los efectos.</p>
<p>Dado que el servicio objeto de contrataci&oacute;n es el transporte de bienes en el que se establece una fecha o un per&iacute;odo de ejecuci&oacute;n espec&iacute;ficos, el Remitente conoce y as&iacute; se le hace saber con anterioridad a la aceptaci&oacute;n de los t&eacute;rminos y condiciones, que a dicha contrataci&oacute;n no le resulta aplicable el derecho de desistimiento tal como establece el art. 103 l) del R. D Legislativo 1/2007, de 16 de noviembre por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios.</p>
<p>Primero: Declaraciones y garant&iacute;as del remitente:</p>
<p>El Remitente indemnizar&aacute; y mantendr&aacute; ilesa a&nbsp;Postalven&nbsp;de cualquier p&eacute;rdida o da&ntilde;o que se derive del incumplimiento por parte del Remitente de cualquier legislaci&oacute;n o normativa aplicable y del incumplimiento por parte del Remitente de cualquiera de las siguientes declaraciones y garant&iacute;as: que la informaci&oacute;n facilitada por el Remitente o sus representantes es completa y exacta; que el Env&iacute;o no est&aacute; sujeto a las restricciones de IATA o la ICAO, ni sean art&iacute;culos prohibidos; que el Env&iacute;o est&aacute; debidamente identificado y cumplimentado correctamente el albar&aacute;n; que el Env&iacute;o est&aacute; correctamente embalado de forma que asegure su transporte por v&iacute;a a&eacute;rea o carretera de forma segura, con el cuidado de manejo ordinario; que se han cumplido todas las legislaciones y normativas aplicables de aduanas, importaci&oacute;n, exportaci&oacute;n y similares; y que el Albar&aacute;n ha sido firmado por un representante autorizado del Remitente y los T&eacute;rminos y Condiciones constituyen obligaciones vinculantes y exigibles al Remitente.</p>
<p>Segundo: Despacho aduanero:</p>
<p>2.1. En su calidad de Remitente del env&iacute;o, nos designa como su representante exclusivamente a los efectos de la gesti&oacute;n aduanera y despacho del env&iacute;o por aduanas y, asimismo, reconoce que nosotros somos el consignatario a los solos efectos de designar a un Agente de Aduanas que lleve a cabo el despacho aduanero del env&iacute;o si subcontratamos estos cometidos.</p>
<p>2.2 Es responsabilidad del Remitente asegurar que se suministra toda la documentaci&oacute;n necesaria para el cumplimiento de todas las leyes, normas y reglamentos aplicables, en materia aduanera, importaci&oacute;n y exportaci&oacute;n y reglamentos administrativos de cualquier pa&iacute;s por el que transite.&nbsp;Postalven no asumir&aacute; responsabilidad ante el Remitente ni terceros respecto a p&eacute;rdidas, gastos, retrasos o no entrega del Env&iacute;o derivados del incumplimiento de la presente estipulaci&oacute;n o por interferencias por parte de autoridades aduaneras o gubernamentales.&nbsp;</p>
<p>2.3 Cualquier multa de car&aacute;cter aduanero, impuestos, gastos de almacenaje u otros derivados de&nbsp;las acciones llevadas a cabo por las Autoridades Aduaneras o Administrativas, o por no haberse facilitado la documentaci&oacute;n, licencias o permisos necesarios, ser&aacute; cargo del remitente o destinatario. En el supuesto que el destinatario rechazara el pago, &eacute;ste ser&aacute; asumido por el remitente junto con los cargos administrativos asociados y cualquier coste extra.</p>
<p>Tercero: Mercanc&iacute;as restringidas:</p>
<p>3.1 Mercanc&iacute;as excluidas y restringidas: Queda excluido el transporte de mercanc&iacute;as cuyo contenido sea contrario a las leyes o normativas aplicables y/o aqu&eacute;llas que&nbsp;Postalven&nbsp;tiene restringido su transporte (dichas mercanc&iacute;as incluyen, a t&iacute;tulo meramente enunciativo, animales vivos, dinero, joyas y metales preciosos, valores negociables, obras de arte y antig&uuml;edades, productos perecederos, mercanc&iacute;as peligrosas, etc.), adem&aacute;s podr&aacute;n ser de aplicaci&oacute;n otras restricciones dependiendo del destino del env&iacute;o.</p>
<p>3.2 Mercanc&iacute;as especiales por su peso o dimensi&oacute;n: El peso m&aacute;ximo de cada paquete est&aacute; limitado a 40 kg. Si excediera dicho l&iacute;mite se cobrar&aacute; el suplemento correspondiente. Adem&aacute;s, la suma del largo m&aacute;s el doble del ancho m&aacute;s el doble del alto (Largo + 2 x Ancho + 2 x Alto) no podr&aacute; superar los 3 metros, no pudiendo ser el largo en ning&uacute;n caso superior a 2 metros.</p>
<p>Cuarto: Entregas:</p>
<p>Los env&iacute;os no pueden ser enviados a apartados o c&oacute;digos postales. Los env&iacute;os son entregados en la direcci&oacute;n del Aliado Comercial indicado por el Remitente si bien no necesariamente al Destinatario designado personalmente.&nbsp;Postalven&nbsp;se reserva el derecho a modificar los horarios acordados para las entregas o recogidas, en funci&oacute;n de sus propias causas organizativas. La firma digitalizada del destinatario, as&iacute; como su reproducci&oacute;n, ser&aacute; prueba suficiente de la entrega y las partes reconocen a esta firma un valor id&eacute;ntico al de una firma tradicional sobre papel. Los mismos efectos surtir&aacute; el sello del Destinatario.</p>
<p>Quinto: Inspecci&oacute;n y seguridad:</p>
<p>Postalven&nbsp;se reserva el derecho de abrir e inspeccionar un Env&iacute;o sin notificaci&oacute;n previa al Remitente, en los casos legalmente establecidos. Los reglamentos en vigor en diversas jurisdicciones exigen a&nbsp;Postalven realizar inspecciones con rayos X, renunciando por el presente el Remitente y el destinatario a cualesquiera posibles reclamaciones de da&ntilde;os que pudieran derivarse de las mismas.</p>
<p>Sexto: Cargos de env&iacute;o y facturaci&oacute;n:</p>
<p>Los cargos de Env&iacute;o de&nbsp;Postalven&nbsp;se calculan en funci&oacute;n del peso real o volum&eacute;trico, aplic&aacute;ndose el mayor, y cualquier Env&iacute;o podr&aacute; ser pesado y medido de nuevo por&nbsp;Postalven&nbsp;para confirmar este c&aacute;lculo. El Remitente pagar&aacute; o reembolsar&aacute; a&nbsp;Postalven&nbsp;todos los cargos del env&iacute;o, de almacenamiento, aranceles e impuestos adeudados por los servicios prestados por&nbsp;Postalven&nbsp;o incurridos por&nbsp;Postalven&nbsp;en nombre del Remitente, del Destinatario o de cualquier tercero y todos los gastos por reclamaciones, da&ntilde;os y perjuicios, multas y otros conceptos si el Env&iacute;o se considera inaceptable para el transporte.</p>
<p>S&eacute;ptimo: Renuncia y suspensi&oacute;n del transporte:</p>
<p>Postalven se reserva el derecho de renunciar a transportar la mercanc&iacute;a o a suspender el transporte si ya se hubiera iniciado, en los casos de incumplimiento por parte del Remitente o Destinatario de lo establecido en los presentes T&eacute;rminos y Condiciones y especialmente de lo dispuesto en los apartados 2 y 3. Igualmente&nbsp;Postalven&nbsp;puede suspender el transporte en caso de que no pudiera hacer efectiva la entrega al Destinatario por impago de portes, ausencia, reh&uacute;se o deficiencias en sus datos. En estos casos, se lo comunicar&aacute; al Remitente y &eacute;ste no tendr&aacute; derecho a la devoluci&oacute;n de los portes que hubiera abonado. Igualmente ser&aacute;n su cargo todos los gastos que se generen, incluidos, en su caso los correspondientes a la devoluci&oacute;n a origen, su dep&oacute;sito y posteriores entregas. Si, habiendo suspendido el transporte,&nbsp;Postalven&nbsp;no obtiene en un tiempo razonable instrucciones del Remitente sobre c&oacute;mo disponer de la mercanc&iacute;a, Postalven tendr&aacute; derecho a destruir, enajenar o donar la misma.</p>
<p>Octavo: Responsabilidad de Postalven:</p>
<p>El Remitente acepta que la responsabilidad de&nbsp;Postalven&nbsp;se limita estrictamente a la p&eacute;rdida directa, &uacute;nica y exclusivamente en funci&oacute;n de los l&iacute;mites por kilogramo que se establecen en el presente Art&iacute;culo. Quedan excluidos todos los dem&aacute;s tipos de p&eacute;rdida o da&ntilde;o indirectos o de personal consideraci&oacute;n (tales como, a t&iacute;tulo meramente enunciativo, el lucro cesante, la p&eacute;rdida de intereses y de futuros negocios) incluso aunque se hubiera avisado a&nbsp;Postalven sobre el riesgo de dicha p&eacute;rdida o da&ntilde;o antes o despu&eacute;s de la aceptaci&oacute;n del Env&iacute;o. Dichos l&iacute;mites son los siguientes:</p>
<p>a)&nbsp;Transporte internacional a&eacute;reo: Si el transporte de su env&iacute;o se lleva a cabo total o parcialmente por v&iacute;a a&eacute;rea e implica un &uacute;ltimo destino o una escala intermedia en un pa&iacute;s distinto al pa&iacute;s de origen, ser&aacute; aplicable el Convenio de Varsovia de 1929, modificado por el Protocolo de la Haya de 1955 y/o el Protocolo de Montreal N&ordm; 4 de 1975, o la Convenci&oacute;n de Montreal de 1999, el que fuera aplicable obligatoriamente. Estos tratados internacionales rigen y limitan nuestra responsabilidad por p&eacute;rdida, da&ntilde;o o retraso de su env&iacute;o a la cantidad de 22 (DEG) Derechos Especiales de Giro por kilo.</p>
<p>b)&nbsp;Transporte internacional terrestre: Si el transporte de su env&iacute;o se lleva a cabo total o parcialmente por carretera, dentro, hacia o desde un pa&iacute;s que forma parte de la convenci&oacute;n que ampara el contrato de transporte internacional de mercanc&iacute;as por carretera de 1956 ('CMR'), nuestra responsabilidad por p&eacute;rdida o da&ntilde;o de su env&iacute;o o parte afectada del mismo se regir&aacute; por el CMR y se limita a la cantidad de 8,33 (DEG) Derechos Especiales de Giro por kilo.&nbsp;</p>
<p>En caso de que el Remitente considerara insuficientes dichos l&iacute;mites puede concertar un seguro de valor declarado: La cobertura de seguro ser&aacute; como m&aacute;ximo el importe declarado en el albar&aacute;n en el apartado destinado a este fin, que ser&aacute; igual o inferior al importe neto mostrado en la factura de la mercanc&iacute;a, con un l&iacute;mite hasta 3.000 &euro; por env&iacute;o. A los referidos efectos de cobertura, corresponder&aacute; el pago del 3% del valor que se declare para carga general y del 5% para carga electr&oacute;nica. Para la tramitaci&oacute;n del siniestro deber&aacute; aportar la factura que justifique el importe del bien siniestrado, con independencia de cu&aacute;l sea el valor declarado.&nbsp;</p>
<p>En todos los casos, las reclamaciones se limitar&aacute;n a una por Env&iacute;o y su liquidaci&oacute;n se considerar&aacute; definitiva por todas las p&eacute;rdidas y da&ntilde;os ocasionados en relaci&oacute;n con el mismo. Ser&aacute; requisito indispensable para hacer efectiva una indemnizaci&oacute;n por siniestro, que los gastos que le correspondan en concepto de transporte, seguro y otros, hayan sido debidamente abonados.&nbsp;Postalven&nbsp;se reserva el derecho a recuperar el env&iacute;o para su inspecci&oacute;n y valoraci&oacute;n, por lo que el Remitente o Destinatario deber&aacute;n tener a disposici&oacute;n de&nbsp;Postalven&nbsp;la mercanc&iacute;a da&ntilde;ada hasta la completa resoluci&oacute;n de la reclamaci&oacute;n.</p>
<p>Noveno: Plazo l&iacute;mite para reclamaciones:</p>
<p>Todas las reclamaciones habr&aacute;n de ser enviadas por escrito a&nbsp;Postalven, dentro del plazo m&aacute;ximo de 48 Hrs. desde el momento en que se entrega la carga. En todo caso, las acciones prescribir&aacute;n dentro de los plazos establecidos en la legislaci&oacute;n aplicable al Env&iacute;o.</p>
<p>D&eacute;cimo: Env&iacute;os contra reembolso:</p>
<p>Para ciertos destinos,&nbsp;Postalven&nbsp;ofrece un servicio de entrega contra reembolso, mediante el pago de un cargo adicional. El importe del reembolso deber&aacute; consignarse por el Remitente a trav&eacute;s de los medios de pago que indicados por Postalven en la moneda del pa&iacute;s de destino. Postalven&nbsp;no ser&aacute; responsable de los actos fraudulentos o de mala fe del Destinatario, tales como, cheques falsos, sin fondos o indebidamente cumplimentados, ni del buen fin de los mismos. La responsabilidad de&nbsp;Postalven&nbsp;queda limitada en todo caso al importe del reembolso, el cual no podr&aacute; superar nunca el valor de la mercanc&iacute;a. El importe del reembolso no es considerado como valor declarado y, por tanto, no afecta a la responsabilidad por da&ntilde;os o p&eacute;rdidas de las mercanc&iacute;as.</p>
<p>Und&eacute;cimo: Exclusiones:</p>
<p>Postalven&nbsp;har&aacute; cuanto razonablemente obre en su poder para entregar el Env&iacute;o de conformidad con los plazos normales de entrega de&nbsp;Postalven, pero &eacute;stos no est&aacute;n garantizados y no forman parte del contrato.&nbsp;Postalven no asumir&aacute; ninguna p&eacute;rdida o da&ntilde;o ocasionado por demoras.</p>
<p>Duod&eacute;cimo: Exclusiones:</p>
<p>Postalven&nbsp;no ser&aacute; responsable por p&eacute;rdida, da&ntilde;o, demora, entrega incorrecta o falta de entrega de su env&iacute;o o de parte del mismo en los siguientes casos: circunstancias ajenas a su control, incluyendo, a modo meramente enunciativo: desastres medioambientales; causas de fuerza mayor; sublevaciones, tumultos o conmociones civiles; cualquier defecto o caracter&iacute;stica relacionada con la naturaleza del Env&iacute;o, aunque sea conocida por&nbsp;Postalven; cualquier acci&oacute;n u omisi&oacute;n causada por cualquier persona que no sea empleado contratado por&nbsp;Postalven; da&ntilde;os el&eacute;ctricos o magn&eacute;ticos, o borrado de im&aacute;genes, datos o grabaciones electr&oacute;nicas o fotogr&aacute;ficas; env&iacute;os de mercanc&iacute;as excluidas y restringidas, falta o insuficiencia de embalaje y, en general, cualquier incumplimiento del Remitente y/o Destinatario de lo dispuesto en los presentes T&eacute;rminos y Condiciones.</p>
<p>Decimotercero: Nulidad e ineficacia parcial:</p>
<p>Si algunas de las cl&aacute;usulas o parte de las mismas fueran nulas o ineficaces permanecer&aacute;n subsistentes el resto de los T&eacute;rminos y Condiciones no afectados por la nulidad o ineficacia.</p>
<p>Decimocuarto: Legislaci&oacute;n aplicable y jurisdicci&oacute;n:</p>
<p>Al servicio de transporte contratado le ser&aacute; de aplicaci&oacute;n lo dispuesto en los Convenios internacionales de aplicaci&oacute;n y, subsidiariamente, a la legislaci&oacute;n sectorial espa&ntilde;ola.</p>
<p>Para cuantas cuestiones litigiosas pudieran surgir en la aplicaci&oacute;n o interpretaci&oacute;n de las presentes condiciones, ambas partes se someten a los Juzgados y Tribunales espa&ntilde;oles que sean competentes con arreglo a lo dispuesto en la normativa espa&ntilde;ola vigente y el fuero competente en el caso de consumidores, con expresa renuncia a cualquier otro procedimiento que pudiera corresponderles quedando excluido el sometimiento de la cuesti&oacute;n litigiosa a cualquier tipo de arbitraje, nacional o internacional.</p>
<p>Decimoquinto: Reclamaciones:</p>
<p>Para la resoluci&oacute;n de gesti&oacute;n de incidencias y reclamaciones, relativas a la prestaci&oacute;n de los Servicios contratados, deber&aacute; dirigirse a&nbsp;Postalven&nbsp;mediante los siguientes canales de comunicaci&oacute;n:&nbsp;</p>
<p>Canal telef&oacute;nico: 0034 91 993 12 13</p>
<p>WEB:&nbsp;<u><a href="http://www.postalven.com">www.postalven.com</a></u>&nbsp;</p>
<p>Adem&aacute;s, le informamos de la existencia de una plataforma de resoluci&oacute;n de litigios en l&iacute;nea de la Uni&oacute;n Europea a la que puede acceder a trav&eacute;s del siguiente enlace:&nbsp;<a href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show">https://ec.europa.eu/consumers/odr/main/?event=main.home2.show</a></p>

                    </>
                  }
                />
              }
            />
            <Route path="/orderhistory" element={<OrderHistory />} />
            <Route path="/agencias" element={<Agencies />} />
          </>
        ) : (
          <>
            {" "}
            <Route
              path="/"
              element={
                <div
                  style={{
                    height: "100vh",
                    position: "fixed",
                    width: "100%",
                    background: "lightblue",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    top: 0,
                  }}
                >
                  <h1 className="fw-bold">Coming Soon!</h1>
                </div>
              }
            />
            <Route
              path="*"
              element={
                <div
                  style={{
                    height: "100vh",
                    position: "fixed",
                    width: "100%",
                    background: "lightblue",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    top: 0,
                  }}
                >
                  <h1 className="fw-bold">Coming Soon!</h1>
                </div>
              }
            />
          </>
        )}
      </Routes>
      {show && location.pathname !== "/verify" && <Footer />}
    </>
  );
}

export default App;
